import { Table, Button, Form, Dropdown } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { Modal } from "@mui/material";

import "react-datepicker/dist/react-datepicker.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};
const Billquote = () => {
  const [invoiceDetails, setInvoiceDetails] = useState({
    items: [],
    discountRate: 0,
  });

  const location = useLocation();
  const [outletId, setOutletId] = useState(null);
  const { quotationData } = location.state || {};
  const [outletDetails, setOutletDetails] = useState(null);
  const [customerDetails, setCustomerDetails] = useState({});

  const [username, setUsername] = useState("");

  const [selectedAccount, setSelectedAccount] = useState(null);
  const [settinglist, setSettinglist] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState("Cash");
  const [paidAmount, setPaidAmount] = useState(0);
  const [returnChange, setReturnChange] = useState(0);

  const formatDate = (date) => {
    return `${date.getDate().toString().padStart(2, "0")}/${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${date.getFullYear()}`;
  };

  useEffect(() => {
    if (quotationData && quotationData.quotationId) {
      const token = localStorage.getItem("token");
      const usernameFromStorage = localStorage.getItem("username"); // Get username from localStorage
      console.log(usernameFromStorage);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      if (usernameFromStorage) {
        setUsername(usernameFromStorage); // Set username in state if found
      }

      // Fetch the quotation details
      axios
        .get(
          `https://luckytraders.bwsoft.in/api/quotation/${quotationData.quotationId}/`,
          config
        )
        .then((response) => {
          console.log("Quotation Details:", response.data);
          const outletId = response.data.outlet_id;
          const customer = response.data.customer || {};
          setCustomerDetails(customer);
          setOutletId(outletId);
          console.log("Customer Details:", customer);
          const items = response.data.products.map((productItem) => {
            console.log("retail_price:", productItem.price);
            return {
              id: productItem.product.id,
              name: productItem.product.name,
              code: productItem.product.code,
              quantity: parseInt(productItem.quantity, 10) || 1,
              price: productItem.product.retail_price
                ? parseFloat(productItem.product.retail_price)
                : 0,
              gst: parseFloat(productItem.product.gst) || 0,
              igst: parseFloat(productItem.product.igst) || 0,
              totalprice: parseFloat(productItem.total_price) || 0,
            };
          });

          setInvoiceDetails((prevDetails) => ({
            ...prevDetails,
            items,
            outletId, // Store the outletId
          }));

          // Now fetch the outlet details using outletId
          axios
            .get(
              `https://luckytraders.bwsoft.in/api/getoutlet/${outletId}/`,
              config
            )
            .then((response) => {
              setOutletDetails(response.data); // Set the outlet details in the state
            })
            .catch((error) => {
              console.error("Error fetching outlet details:", error);
            });
        })
        .catch((error) => {
          console.error("Error fetching quotation details:", error);
        });
    }

    // Fetch payment methods
    const token = localStorage.getItem("token"); // Make sure 'token' is available here as well

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    axios
      .get("https://luckytraders.bwsoft.in/api/paymentlist/", config)
      .then((response) => {
        if (Array.isArray(response.data)) {
          setPaymentMethods(response.data.filter((method) => method.status));
          setPaymentMethod(response.data[0]?.name || "Cash"); // Set default payment method
        }
      })
      .catch((error) =>
        console.error("Error fetching payment methods:", error)
      );
  }, [quotationData]);

  useEffect(() => {
    const fetchSettingList = async () => {
      const token = localStorage.getItem("token");

      try {
        const response = await fetch(
          "https://luckytraders.bwsoft.in/api/settinglist/",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) throw new Error("Failed to fetch settings");

        const data = await response.json();
        console.log(data); // Log to check the structure
        setSettinglist(Array.isArray(data) ? data : []); // Ensure it's an array
      } catch (error) {
        console.error("Error fetching setting list:", error);
      }
    };

    fetchSettingList();
  }, []);

  const handleQuantityChange = (itemId, amount) => {
    setInvoiceDetails((prevDetails) => ({
      ...prevDetails,
      items: prevDetails.items.map((item) =>
        item.id === itemId
          ? { ...item, quantity: Math.max(1, item.quantity + amount) }
          : item
      ),
    }));
  };
  const handleDiscountChange = (e) => {
    const newDiscountRate = parseFloat(e.target.value) || 0; // Make sure to handle invalid inputs
    setInvoiceDetails((prevDetails) => ({
      ...prevDetails,
      discountRate: newDiscountRate,
    }));
  };

  const subtotal = invoiceDetails.items.reduce(
    (acc, item) => acc + Number(item.price || 0) * Number(item.quantity || 0),
    0
  );

  const totalGst = invoiceDetails.items.reduce(
    (acc, item) => acc + ((item.gst * item.price * item.quantity) / 100 || 0),
    0
  );
  const totalIgst = invoiceDetails.items.reduce(
    (acc, item) => acc + ((item.igst * item.price * item.quantity) / 100 || 0),
    0
  );

  const discountAmount = (subtotal * invoiceDetails.discountRate) / 100;
  const totalPayable = subtotal + totalIgst + totalGst - discountAmount;

  const navigate = useNavigate();

  const handleMakePayment = () => {
    if (!paymentMethod) {
      alert("Please select a payment method.");
      return; // Exit if no payment method is selected
    }

    const gst_charge = totalGst.toFixed(2);
    const igst_charge = totalIgst.toFixed(2);

    const orderData = {
      card_number: "",
      cgst_charge: "0.00",
      cgst_percent: "0.00",
      created_by: 1,
      customer_id: customerDetails.id,
      discount_percentage: "0.00",
      discount_total: discountAmount.toFixed(2),
      grand_total: totalPayable.toFixed(2),
      igst_charge: igst_charge,
      gst_charge: gst_charge,
      order_items: invoiceDetails.items.map((item) => ({
        igst_percent: "0.00",
        gst_percent: "0.00",
        product_id: item.id,
        product_name: item.name,
        product_code: item.code,
        qty: item.quantity,
        category_id: item.categoryId || 0,
        purchase_price: item.purchasePrice || "0.00",
        retail_price: item.price || "0.00",
      })),
      outlet_id: outletId,
      paid_amount: paidAmount,
      payment_id: paymentMethod, // Using paymentMethod ID as the payment_id
      refund_status: 0,
      remark: "",
      return_change: (paidAmount - totalPayable).toFixed(2),
      sgst_charge: "0.00",
      sgst_percent: "0.00",
      status: 1,
      subtotal: subtotal.toFixed(2),
      total_items: invoiceDetails.items.length,
      updated_by: null,
      vt_status: 1,
      selected_account: selectedAccount,
    };

    const token = localStorage.getItem("token");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    axios
      .post(
        "https://luckytraders.bwsoft.in/api/create_order/",
        orderData,
        config
      )
      .then((response) => {
        console.log("Order created successfully:", response.data);
        console.log("Selected Account:", selectedAccount);
        navigate("/Createbill", {
          state: {
            orderDetails: response.data,
            selectedAccount: selectedAccount.id,
          },
        });
      })
      .catch((error) => {
        console.error("Error creating order:", error.response.data);
      });
  };

  const handleRemoveItem = (id) => {
    setInvoiceDetails((prevDetails) => ({
      ...prevDetails,
      items: prevDetails.items.filter((item) => item.id !== id),
    }));
  };

  useEffect(() => {
    setReturnChange(paidAmount - totalPayable); // Update return change dynamically
  }, [paidAmount, totalPayable]);

  return (
    <div className="bill-template" id="tabelcontent">
      <h1 className="text-center">Invoice</h1>

      <div className="d-flex justify-content-between align-items-start">
        <div>
          <p>Issued to:</p>
          <h5>{customerDetails.name || "Loading..."}</h5>
          <p>{customerDetails.address || "Loading..."}</p>
          <p>GST: {customerDetails?.customer_gst_no || "N/A"}</p>
          <p>Email: {customerDetails.email || "Loading..."}</p>
          <p>Mobile: {customerDetails.mobile || "Loading..."}</p>
        </div>
        <div>
          <p>Shipping Address</p>
          <p>{customerDetails?.delivery_name || "N/A"}</p>
          <p>{customerDetails?.delivery_address || "N/A"}</p>
          <p>GST:{customerDetails?.delivery_gst_no || "N/A"}</p>
        </div>

        <div className=" create-bill">
          <h5 className="mb-4"></h5>
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-start ">
              <p className="mb-1 billcreate-left">Quotation Id</p>
              <p className="billcreate-leftdot">:</p>
              <p className="mb-1 justify-content-start">
                <strong>{quotationData.quotationId}</strong>
              </p>
            </div>

            <div className="d-flex justify-content-start ">
              <p className="mb-1 billcreate-left">Payment Mode</p>
              <p className="billcreate-leftdot">:</p>
              <p className="mb-1 d-flex justify-content-start ">
                <strong>Cash</strong>
              </p>
            </div>
            {/* <div className="d-flex justify-content-start ">
              <p className="mb-1 billcreate-left">Sales Rep</p>
              <p className="billcreate-leftdot">:</p>
              <p className="mb-1 justify-content-start">
                <strong>{username || "Loading..."}</strong>{" "}
                
              </p>
            </div> */}
            <div className="d-flex justify-content-start ">
              <p className="mb-1 billcreate-left">Branch Number</p>
              <p className="billcreate-leftdot">:</p>
              <p className="mb-1 justify-content-start">
                <strong>{outletDetails?.name || "Loading..."}</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Table striped bordered hover className="bill-table">
        <thead>
          <tr>
            <th>#</th>
            <th>Product Name</th>
            <th>HSN Code</th>
            <th>Quantity</th>
            <th>Price</th>
            <th>Gst</th>
            <th>IGst</th>
            <th>Total</th>
            <th>Remove</th>
          </tr>
        </thead>
        <tbody>
          {invoiceDetails.items.map((item, index) => (
            <tr key={item.id}>
              <td>{index + 1}</td>
              <td>{item.name}</td>
              <td>{item.code}</td>
              <td>
                <div className="quantity-control">
                  <button
                    className="quantity-button"
                    onClick={() => handleQuantityChange(item.id, -1)}
                  >
                    -
                  </button>
                  <input
                    type="number"
                    value={item.quantity}
                    onChange={(e) =>
                      handleQuantityChange(item.id, e.target.value)
                    }
                    min="0"
                    className="quantity-input"
                  />
                  <button
                    className="quantity-button"
                    onClick={() => handleQuantityChange(item.id, 1)}
                  >
                    +
                  </button>
                </div>
              </td>
              {/* Check if the retail price is being correctly parsed */}
              <td>₹{parseFloat(item.price).toFixed(2)}</td>
              <td>{item.gst ? `${item.gst}%` : "0%"}</td>
              <td>{item.igst ? `${item.igst}%` : "0%"}</td>

              <td>{item.totalprice}</td>
              <td>
                <button
                  className="btn btn-danger"
                  onClick={() => handleRemoveItem(item.id)}
                >
                  X
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Table striped bordered hover className="custom-bill-summary-table">
        <thead>
          <tr>
            <th colSpan={4} className="table-title">
              Bill Summary
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="label">Total Purchase Quantity</td>

            <td>
              {invoiceDetails.items.reduce(
                (acc, item) => acc + item.quantity,
                0
              )}
            </td>
            <td className="label">Subtotal</td>
            <td>₹{subtotal.toFixed(2)}</td>
          </tr>
          <tr>
            <td className="label">Discount Rate (%):</td>
            <td>
              <input
                type="text"
                id="discount"
                value={invoiceDetails.discountRate}
                onChange={handleDiscountChange}
                min="0"
                max="100"
                step="0.1"
              />
            </td>

            <td className="label">GST Total</td>
            <td>₹{totalGst.toFixed(2)}</td>
          </tr>
          <tr>
            <td className="label">Discount ({invoiceDetails.discountRate}%)</td>
            <td>-₹{discountAmount.toFixed(2)}</td>
            <td className="label">IGST Total</td>
            <td>₹{totalIgst.toFixed(2)}</td>
          </tr>
          <tr>
            <td colSpan={2} className="total-label">
              <strong>Total Payable</strong>
            </td>
            <td colSpan={2} className="total-value">
              <strong>₹{totalPayable.toFixed(2)}</strong>
            </td>
          </tr>
        </tbody>
      </Table>

      <div className="bill-footer d-flex g-2 justify-content-evenly">
        <Button variant="danger">Cancel</Button>
        <Button variant="danger"> Hold Bill</Button>
        <Button variant="primary" onClick={() => setModalOpen(true)}>
          Make Payment
        </Button>
      </div>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <div className="billquot" style={style}>
          <h3>Select Payment Details</h3>

          <div className="create-bill">
            <Form.Label>selectedpaymentMethods</Form.Label>
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {paymentMethods.length === 0
                  ? "Loading..."
                  : paymentMethods.find((method) => method.id === paymentMethod)
                  ? paymentMethods.find((method) => method.id === paymentMethod)
                      .name
                  : "Select Payment Method"}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {paymentMethods.map((method) => (
                  <Dropdown.Item
                    key={method.id}
                    onClick={() => setPaymentMethod(method.id)} // Set the selected payment method ID
                  >
                    {method.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            {/* Select Account Dropdown */}
            <Form.Label>selectedAccount</Form.Label>
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-account">
                {selectedAccount && selectedAccount.name
                  ? selectedAccount.name
                  : "Select Account"}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {settinglist.map((account) => (
                  <Dropdown.Item
                    key={account.id}
                    onClick={() => setSelectedAccount(account)}
                  >
                    {account.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            <Form.Group controlId="paidAmount">
              <Form.Label>Paid Amount</Form.Label>
              <Form.Control
                type="number"
                value={paidAmount}
                onChange={(e) => setPaidAmount(Number(e.target.value))}
              />
            </Form.Group>

            <Form.Group controlId="returnChange">
              <Form.Label>Return Change</Form.Label>
              <Form.Control
                type="text"
                value={`₹${(paidAmount - totalPayable).toFixed(2)}`}
                readOnly
              />
            </Form.Group>
          </div>

          {/* Submit Button */}
          <div className="d-flex justify-content-evenly">
            <Button variant="danger" onClick={() => setModalOpen(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleMakePayment}>
              Submit Payment
            </Button>
          </div>
        </div>
      </Modal>

      <p>Thank you for your business!</p>

      <style jsx>{`
        .product-dropdown {
          list-style: none;
          padding: 0;
          margin: 0;
          border: 1px solid #ccc;
          max-height: 100px;
          overflow-y: auto;
          position: absolute;
          background-color: white;
          z-index: 10;
          width: calc(30% - 20px);
        }

        .dropdown-item {
          padding: 8px 12px;
          cursor: pointer;
        }

        .dropdown-item:hover {
          background-color: #f1f1f1;
        }

        .bill-table {
          margin-top: 20px;
        }
      `}</style>
    </div>
  );
};

export default Billquote;
