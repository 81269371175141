import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import axios from "axios";

const AddCustomer = () => {
  const [customerName, setCustomerName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const [deliveryName, setDeliveryName] = useState("");
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [deliveryGstNumber, setDeliveryGstNumber] = useState("");
  const [sameAsBilling, setSameAsBilling] = useState(false);
  const [error, setError] = useState("");

  const handleSameAsBilling = (e) => {
    const isChecked = e.target.checked;
    setSameAsBilling(isChecked);

    if (isChecked) {
      setDeliveryName(customerName);
      setDeliveryAddress(address);
      setDeliveryGstNumber(gstNumber);
    } else {
      setDeliveryName("");
      setDeliveryAddress("");
      setDeliveryGstNumber("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const customerData = {
      name: customerName,
      email,
      mobile,
      address,
      customer_gst_no: gstNumber,
      delivery_name: deliveryName,
      delivery_address: deliveryAddress,
      delivery_gst_no: deliveryGstNumber,
    };

    const token = localStorage.getItem("token"); // Get the access token

    try {
      const response = await axios.post(
        "https://luckytraders.bwsoft.in/api/customer/create/",
        customerData,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the access token
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Customer created successfully:", response.data);
      // Optionally reset form fields here
    } catch (error) {
      console.error("Error creating customer:", error);
      setError("Failed to create customer. Please try again.");
    }
  };

  return (
    <div className="container mt-4" id="tabelcontent">
      <h2>Create Customer</h2>
      {error && <p className="text-danger">{error}</p>}
      <Form onSubmit={handleSubmit}>
        {/* Customer Information */}
        <h4>Customer Information</h4>
        <Form.Group controlId="formCustomerName">
          <Form.Label>Name *</Form.Label>
          <Form.Control
            type="text"
            value={customerName}
            onChange={(e) => setCustomerName(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formEmail">
          <Form.Label>Email Address *</Form.Label>
          <Form.Control
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formMobile">
          <Form.Label>Mobile Number *</Form.Label>
          <Form.Control
            type="text"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formAddress">
          <Form.Label>Address</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="formGstNumber">
          <Form.Label>GST Number</Form.Label>
          <Form.Control
            type="text"
            value={gstNumber}
            onChange={(e) => setGstNumber(e.target.value)}
          />
        </Form.Group>

        {/* Delivery Information */}
        <h4>Customer Delivery Address</h4>
        <Form.Check
          type="checkbox"
          label="Same as billing name & address"
          checked={sameAsBilling}
          onChange={handleSameAsBilling}
        />

        <Form.Group controlId="formDeliveryName">
          <Form.Label>Delivery Name *</Form.Label>
          <Form.Control
            type="text"
            value={deliveryName}
            onChange={(e) => setDeliveryName(e.target.value)}
            required={!sameAsBilling}
            disabled={sameAsBilling}
          />
        </Form.Group>

        <Form.Group controlId="formDeliveryAddress">
          <Form.Label>Delivery Address *</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={deliveryAddress}
            onChange={(e) => setDeliveryAddress(e.target.value)}
            required={!sameAsBilling}
            disabled={sameAsBilling}
          />
        </Form.Group>

        <Form.Group controlId="formDeliveryGstNumber">
          <Form.Label>Delivery GST Number</Form.Label>
          <Form.Control
            type="text"
            value={deliveryGstNumber}
            onChange={(e) => setDeliveryGstNumber(e.target.value)}
            disabled={sameAsBilling}
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Create Customer
        </Button>
      </Form>
    </div>
  );
};

export default AddCustomer;
