import React, { useEffect, useState } from "react";
import { Form, Button, Spinner } from "react-bootstrap"; // Import necessary components
import Swal from "sweetalert2"; // For alert dialogs
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import { useParams, useNavigate } from "react-router-dom"; // For routing

const Editsystemsetting = () => {
  const { id } = useParams(); // Get the setting ID from URL
  const navigate = useNavigate(); // For navigation
  const [setting, setSetting] = useState({
    name: "",
    logo: null, // Change to null for file upload
    tax: 0,
  });
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchSetting = async () => {
      const token = localStorage.getItem("token"); // Retrieve access token

      try {
        const response = await fetch(
          `https://luckytraders.bwsoft.in/api/setting/update/${id}/`, // Fetch specific setting
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) throw new Error("Failed to fetch setting data");

        const data = await response.json();
        setSetting(data); // Set the fetched setting data
      } catch (error) {
        console.error("Error fetching setting:", error);
        Swal.fire("Error!", "Failed to fetch setting data.", "error");
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchSetting(); // Call the function to fetch setting data
  }, [id]);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setSetting({
      ...setting,
      [name]: type === "file" ? files[0] : value, // Handle file input
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token"); // Retrieve access token

    const formData = new FormData(); // Create FormData object
    formData.append("name", setting.name);
    formData.append("logo", setting.logo); // Append logo file
    formData.append("tax", setting.tax);

    try {
      const response = await fetch(
        `https://luckytraders.bwsoft.in/api/setting/update/${id}/`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            // "Content-Type": "application/json", // Remove this line when using FormData
          },
          body: formData, // Send FormData
        }
      );

      if (response.ok) {
        Swal.fire("Success!", "Setting updated successfully.", "success");
        navigate("/systemsettings"); // Redirect after successful update
      } else {
        throw new Error("Failed to update setting");
      }
    } catch (error) {
      console.error("Error updating setting:", error);
      Swal.fire("Error!", "Failed to update the setting.", "error");
    }
  };

  if (loading) {
    return (
      <div className="text-center">
        <Spinner animation="border" /> Loading setting data...
      </div>
    ); // Loading message for setting data
  }

  return (
    <div className="container mt-4" id="tabelcontent">
      <h1>Update System Setting</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formName">
          <Form.Label>Name *</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={setting.name}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="formLogo">
          <Form.Label>Logo *</Form.Label>
          <Form.Control
            type="file"
            name="logo"
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="formTax">
          <Form.Label>Tax (%) *</Form.Label>
          <Form.Control
            type="number"
            name="tax"
            value={setting.tax}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Button variant="primary" type="submit" className="mt-3">
          Update Setting
        </Button>
      </Form>
    </div>
  );
};

export default Editsystemsetting;
