import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";

const AddInventory = () => {
  const [product, setProduct] = useState("");
  const [outlet, setOutlet] = useState("");
  const [quantity, setQuantity] = useState("");
  const [productsList, setProductsList] = useState([]);
  const [outletsList, setOutletsList] = useState([]);

  useEffect(() => {
    const fetchProductsAndOutlets = async () => {
      const token = localStorage.getItem("token"); // Get the access token
      try {
        // Fetch Products
        const productsResponse = await axios.get(
          "https://luckytraders.bwsoft.in/api/productlist/",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setProductsList(productsResponse.data);

        // Fetch Outlets
        const outletsResponse = await axios.get(
          "https://luckytraders.bwsoft.in/api/outletlist/",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setOutletsList(outletsResponse.data);
      } catch (error) {
        console.error("Error fetching products or outlets:", error);
        Swal.fire("Error!", "Failed to fetch products or outlets.", "error");
      }
    };

    fetchProductsAndOutlets();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic validation for quantity
    if (parseInt(quantity) <= 0) {
      Swal.fire("Error!", "Quantity must be a positive number.", "error");
      return;
    }

    const inventoryData = {
      product_id: parseInt(product),
      outlet_id: parseInt(outlet),
      qty: parseInt(quantity),
    };

    const token = localStorage.getItem("token");

    try {
      await axios.post(
        "https://luckytraders.bwsoft.in/api/inventory/create/",
        inventoryData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      Swal.fire("Success!", "Inventory added successfully.", "success");
      // Reset form fields
      setProduct("");
      setOutlet("");
      setQuantity("");
    } catch (error) {
      console.error("Error adding inventory:", error);

      // Check for specific error response
      if (error.response && error.response.data.message) {
        Swal.fire("Error!", error.response.data.message, "error");
      } else {
        Swal.fire("Error!", "Failed to add inventory.", "error");
      }
    }
  };

  return (
    <div className="container mt-4" id="tabelcontent">
      <h2>Create Inventory</h2>
      <Form onSubmit={handleSubmit}>
        {/* Select Product */}
        <Form.Group controlId="formProduct">
          <Form.Label>Select Product *</Form.Label>
          <Form.Control
            as="select"
            value={product}
            onChange={(e) => setProduct(e.target.value)}
            required
          >
            <option value="">-- Select Product --</option>
            {productsList.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name} ({item.code}){" "}
                {/* Adjust based on your actual data structure */}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        {/* Select Outlet */}
        <Form.Group controlId="formOutlet">
          <Form.Label>Select Outlet *</Form.Label>
          <Form.Control
            as="select"
            value={outlet}
            onChange={(e) => setOutlet(e.target.value)}
            required
          >
            <option value="">-- Select Outlet --</option>
            {outletsList.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name} {/* Adjust based on your actual data structure */}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        {/* Quantity */}
        <Form.Group controlId="formQuantity">
          <Form.Label>Quantity *</Form.Label>
          <Form.Control
            type="number"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            placeholder="Enter Quantity"
            required
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Add Inventory
        </Button>
      </Form>
    </div>
  );
};

export default AddInventory;
