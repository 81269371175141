import React, { useEffect, useState } from "react";
import { Table, Spinner, Button } from "react-bootstrap"; // Import necessary components
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import { Link } from "react-router-dom";
import Swal from "sweetalert2"; // For alert dialogs

const Systemsettings = () => {
  const [settings, setSettings] = useState([]); // State for settings data
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchSettings = async () => {
      const token = localStorage.getItem("token"); // Retrieve access token

      try {
        const response = await fetch(
          "https://luckytraders.bwsoft.in/api/settinglist/",
          {
            method: "GET", // Use GET method for this API
            headers: {
              Authorization: `Bearer ${token}`, // Include token in the request
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) throw new Error("Failed to fetch settings");

        const data = await response.json();
        setSettings(data); // Set the settings data
      } catch (error) {
        console.error("Error fetching settings:", error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchSettings(); // Call the function to fetch settings
  }, []);

  const handleDelete = async (id) => {
    const token = localStorage.getItem("token"); // Retrieve access token

    // Show confirmation dialog
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "This action cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    });

    if (result.isConfirmed) {
      try {
        const response = await fetch(
          `https://luckytraders.bwsoft.in/api/setting/delete/${id}/`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${token}`, // Include token in the request
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          Swal.fire("Deleted!", "The setting has been deleted.", "success");
          // Remove the deleted setting from state
          setSettings(settings.filter((setting) => setting.id !== id));
        } else {
          throw new Error("Failed to delete setting");
        }
      } catch (error) {
        console.error("Error deleting setting:", error);
        Swal.fire("Error!", "Failed to delete the setting.", "error");
      }
    }
  };

  if (loading) {
    return (
      <div className="text-center">
        <Spinner animation="border" /> Loading settings...
      </div>
    ); // Loading spinner
  }

  return (
    <div className="container mt-4" id="tabelcontent">
      <h1>System Settings</h1>
      <Table striped bordered hover>
        <thead className="table-dark">
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Logo</th>
            <th>Tax (%)</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {settings.length > 0 ? (
            settings.map((setting) => (
              <tr key={setting.id}>
                <td>{setting.id}</td>
                <td>{setting.name}</td>
                <td>
                  <img
                    src={setting.logo}
                    alt={setting.name}
                    style={{ width: "50px" }}
                  />
                </td>
                <td>{setting.tax}</td>
                <td>
                  <Link to={`/Editsystemsetting/${setting.id}`}>
                    <Button variant="warning" size="sm" className="me-2">
                      Edit
                    </Button>
                  </Link>
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => handleDelete(setting.id)} // Call delete function
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" className="text-center">
                No settings found
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default Systemsettings;
