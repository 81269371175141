import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const Charts = () => {
  const areaChartRef = useRef(null);
  const barChartRef = useRef(null);
  const pieChartRef = useRef(null);

  useEffect(() => {
    // Area Chart
    const areaChart = new Chart(areaChartRef.current, {
      type: "line",
      data: {
        labels: ["January", "February", "March", "April", "May", "June"],
        datasets: [
          {
            label: "Sales (in rupee)",
            data: [1200, 1900, 3000, 5000, 2500, 7000],
            backgroundColor: "rgba(78, 115, 223, 0.1)",
            borderColor: "rgba(78, 115, 223, 1)",
            borderWidth: 2,
            fill: true,
          },
        ],
      },
    });

    // Bar Chart
    const barChart = new Chart(barChartRef.current, {
      type: "bar",
      data: {
        labels: ["Product A", "Product B", "Product C", "Product D"],
        datasets: [
          {
            label: "Units Sold",
            data: [50, 75, 150, 100],
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
            ],
            borderWidth: 1,
          },
        ],
      },
    });

    // Pie Chart
    const pieChart = new Chart(pieChartRef.current, {
      type: "doughnut",
      data: {
        labels: ["Online", "In-Store", "Wholesale"],
        datasets: [
          {
            data: [60, 30, 10],
            backgroundColor: [
              "rgba(78, 115, 223, 1)",
              "rgba(28, 200, 138, 1)",
              "rgba(54, 185, 204, 1)",
            ],
          },
        ],
      },
    });

    // Cleanup
    return () => {
      areaChart.destroy();
      barChart.destroy();
      pieChart.destroy();
    };
  }, []);

  return (
    <div className="container-fluid" id="tabelcontent">
      <h1 className="h3 mb-2 text-gray-800">Charts</h1>

      <div className="row">
        <div className="col-xl-8 col-lg-7">
          {/* Area Chart */}
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">Sales Trend</h6>
            </div>
            <div className="card-body">
              <div className="chart-area">
                <canvas ref={areaChartRef}></canvas>
              </div>
              <hr />
              Styling for the area chart can be found in the{" "}
              <code>/js/demo/chart-area-demo.js</code> file.
            </div>
          </div>

          {/* Bar Chart */}
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">
                Top Products
              </h6>
            </div>
            <div className="card-body">
              <div className="chart-bar">
                <canvas ref={barChartRef}></canvas>
              </div>
              <hr />
              Styling for the bar chart can be found in the{" "}
              <code>/js/demo/chart-bar-demo.js</code> file.
            </div>
          </div>
        </div>

        <div className="col-xl-4 col-lg-5">
          {/* Pie Chart */}
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">
                Sales Channels
              </h6>
            </div>
            <div className="card-body">
              <div className="chart-pie pt-4">
                <canvas ref={pieChartRef}></canvas>
              </div>
              <hr />
              Styling for the donut chart can be found in the{" "}
              <code>/js/demo/chart-pie-demo.js</code> file.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Charts;
