import React, { useState } from "react";
import { Form, Button } from "react-bootstrap"; // Import necessary components
import Swal from "sweetalert2"; // For alert dialogs
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS

const CreateOutlet = () => {
  const [outlet, setOutlet] = useState({
    name: "",
    address: "",
    mobile: "",
    receipt_footer: "",
    status: true, // default to true (Active)
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOutlet({
      ...outlet,
      [name]: name === "status" ? value === "true" : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token"); // Retrieve access token

    try {
      const response = await fetch(
        "https://luckytraders.bwsoft.in/api/outlet/create/",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(outlet),
        }
      );

      if (response.ok) {
        Swal.fire("Success!", "Outlet created successfully.", "success");
        // Clear the form
        setOutlet({
          name: "",
          address: "",
          mobile: "",
          receipt_footer: "",
          status: true,
        });
      } else {
        throw new Error("Failed to create outlet");
      }
    } catch (error) {
      console.error("Error creating outlet:", error);
      Swal.fire("Error!", "Failed to create the outlet.", "error");
    }
  };

  return (
    <div className="container mt-4" id="tabelcontent">
      <h1>Create Outlet</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formOutletName">
          <Form.Label>Outlet Name *</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={outlet.name}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="formOutletAddress">
          <Form.Label>Address *</Form.Label>
          <Form.Control
            type="text"
            name="address"
            value={outlet.address}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="formOutletContact">
          <Form.Label>Contact *</Form.Label>
          <Form.Control
            type="text"
            name="mobile"
            value={outlet.mobile}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="formOutletStatus">
          <Form.Label>Status *</Form.Label>
          <Form.Select
            name="status"
            value={outlet.status.toString()}
            onChange={handleChange}
            required
          >
            <option value="true">Active</option>
            <option value="false">Inactive</option>
          </Form.Select>
        </Form.Group>

        <Form.Group controlId="formReceiptFooter">
          <Form.Label>Receipt Footer</Form.Label>
          <Form.Control
            as="textarea"
            name="receipt_footer"
            value={outlet.receipt_footer}
            onChange={handleChange}
            rows={3}
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Create Outlet
        </Button>
      </Form>
    </div>
  );
};

export default CreateOutlet;
