import axios from "axios";

const BASE_URL = "https://luckytraders.bwsoft.in/api/";

// Create an Axios instance
const ApiService = axios.create({
  baseURL: BASE_URL,
});

export const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

export const createCategory = async (categoryData) => {
  const response = await axios.post(
    "https://luckytraders.bwsoft.in/api/category/create/",
    categoryData
  );
  return response.data;
};

// Export setAuthToken to call after logging in or retrieving a token
