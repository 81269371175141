import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  Button,
  Form,
  FormControl,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import Swal from "sweetalert2";

const ManageProduct = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Initialize useNavigate

  const [visibleColumns, setVisibleColumns] = useState({
    name: true,
    productCode: true,
    image: true,
    category: true,
    purchasePrice: true,
    retailPrice: true,
    totalQty: true,
    gst: true, // New field for GST
    igst: true, // New field for IGST
    status: true,
  });

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          "https://luckytraders.bwsoft.in/api/productlist/"
        );
        setProducts(response.data);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch products");
        setLoading(false);
      }
    };
    fetchProducts();
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const toggleColumn = (column) => {
    setVisibleColumns((prev) => ({
      ...prev,
      [column]: !prev[column],
    }));
  };

  const handleDeleteProduct = async (productId) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "Do you want to delete this product?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
      });

      if (result.isConfirmed) {
        await axios.delete(
          `https://luckytraders.bwsoft.in/api/product/delete/${productId}/`
        );
        setProducts(products.filter((product) => product.id !== productId));
        Swal.fire("Deleted!", "The product has been deleted.", "success");
      }
    } catch (error) {
      console.error("Error deleting product:", error);
      Swal.fire("Error!", "Failed to delete the product.", "error");
    }
  };

  const handleEditProduct = (productId) => {
    navigate(`/ProductEdit/${productId}`);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="container mt-4" id="tabelcontent">
      <div className="container d-flex flex-column flex-md-row align-items-center justify-content-between">
        <h2>Manage Products</h2>
        <div className="mb-3 d-flex flex-row flex-md-row gap-2">
          <DropdownButton
            id="dropdown-basic-button"
            title="Column Filters"
            variant="success"
          >
            <Form.Check
              type="checkbox"
              label="Name"
              checked={visibleColumns.name}
              onChange={() => toggleColumn("name")}
            />
            <Form.Check
              type="checkbox"
              label="Product Code"
              checked={visibleColumns.productCode}
              onChange={() => toggleColumn("productCode")}
            />
            <Form.Check
              type="checkbox"
              label="Image"
              checked={visibleColumns.image}
              onChange={() => toggleColumn("image")}
            />
            <Form.Check
              type="checkbox"
              label="Category"
              checked={visibleColumns.category}
              onChange={() => toggleColumn("category")}
            />
            <Form.Check
              type="checkbox"
              label="Purchase Price"
              checked={visibleColumns.purchasePrice}
              onChange={() => toggleColumn("purchasePrice")}
            />
            <Form.Check
              type="checkbox"
              label="Retail Price"
              checked={visibleColumns.retailPrice}
              onChange={() => toggleColumn("retailPrice")}
            />
            <Form.Check
              type="checkbox"
              label="Total Qty"
              checked={visibleColumns.totalQty}
              onChange={() => toggleColumn("totalQty")}
            />
            <Form.Check
              type="checkbox"
              label="GST"
              checked={visibleColumns.gst}
              onChange={() => toggleColumn("gst")}
            />
            <Form.Check
              type="checkbox"
              label="IGST"
              checked={visibleColumns.igst}
              onChange={() => toggleColumn("igst")}
            />
            <Form.Check
              type="checkbox"
              label="Status"
              checked={visibleColumns.status}
              onChange={() => toggleColumn("status")}
            />
          </DropdownButton>
          <Button variant="primary">Add Product</Button>
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <FormControl
          type="search"
          placeholder="Search Product..."
          className="me-2"
          value={searchTerm}
          onChange={handleSearch}
        />
      </div>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            {visibleColumns.name && <th>Name</th>}
            {visibleColumns.productCode && <th>Product Code</th>}
            {visibleColumns.image && <th>Image</th>}
            {visibleColumns.category && <th>Category</th>}
            {visibleColumns.purchasePrice && <th>Purchase Price</th>}
            {visibleColumns.retailPrice && <th>Retail Price</th>}
            {visibleColumns.totalQty && <th>Total Qty</th>}
            {visibleColumns.gst && <th>GST</th>}
            {visibleColumns.igst && <th>IGST</th>}
            {visibleColumns.status && <th>Status</th>}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredProducts.length > 0 ? (
            filteredProducts.map((product) => (
              <tr key={product.id}>
                {visibleColumns.name && <td>{product.name}</td>}
                {visibleColumns.productCode && <td>{product.code}</td>}
                {visibleColumns.image && (
                  <td>
                    {product.image ? (
                      <img src={product.image} alt={product.name} width="50" />
                    ) : (
                      "No Image"
                    )}
                  </td>
                )}
                {visibleColumns.category && <td>{product.category.name}</td>}
                {visibleColumns.purchasePrice && (
                  <td>{product.purchase_price}</td>
                )}
                {visibleColumns.retailPrice && <td>{product.retail_price}</td>}
                {visibleColumns.totalQty && <td>{product.inventory_qty}</td>}
                {visibleColumns.gst && <td>{product.gst}%</td>}
                {visibleColumns.igst && <td>{product.igst}%</td>}
                {visibleColumns.status && (
                  <td>{product.status ? "Available" : "Out of Stock"}</td>
                )}
                <td>
                  <Button
                    variant="warning"
                    size="sm"
                    className="me-2"
                    onClick={() => handleEditProduct(product.id)}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => handleDeleteProduct(product.id)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="11" className="text-center">
                No products found
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default ManageProduct;
