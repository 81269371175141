import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";

import Header from "./Component/mainpage/Header";
import Home from "./Component/Pages/Home";
import Footer from "./Component/mainpage/Footer";
import Charts from "./Component/Pages/Charts";
import Tables from "./Component/Pages/Tables";
import Bill from "./Component/Pages/Bill";
import Quoatation from "./Component/Pages/Quoatation";
import ManageInventory from "./Component/Pages/ManageInventory";
import AddInventory from "./Component/Pages/addInventory";
import ManageCustomer from "./Component/Pages/Managecustomer";
import Addcustomer from "./Component/Pages/Addcustomer";
import Manageproduct from "./Component/Pages/Manageproduct";
import Addproduct from "./Component/Pages/Addproduct";
import ManageCategory from "./Component/Pages/Managecategory";
import Addcategory from "./Component/Pages/Addcategory";
import Authform from "./Component/Pages/Authform";
import { useState, useEffect } from "react";
import EditCategory from "./Component/Pages/EditCategory";
import ProductEdit from "./Component/Pages/ProductEdit";
import Allproductlabel from "./Component/Pages/Allproductlabel";
import UpdateCustomer from "./Component/Pages/Updatecustomer";
import UpdateInventory from "./Component/Pages/Updateinventory";
import Salesreport from "./Component/Pages/Salesreport";
import Returnreport from "./Component/Pages/Returnreport";
import Outlets from "./Component/Settingspage/Outlets";
import Users from "./Component/Settingspage/Users";
import Suppliers from "./Component/Settingspage/Suppliers";
import Systemsettings from "./Component/Settingspage/Systemsettings";
import PaymentMethods from "./Component/Settingspage/PaymentMethods";
import Createoutlet from "./Component/Settingspage/Createoutlet";
import EditOutlet from "./Component/Settingspage/EditOutlet";
import CreateUsers from "./Component/Settingspage/Createusers";
import Edituser from "./Component/Settingspage/Edituser";
import Createsuppliers from "./Component/Settingspage/Createsuppliers";
import Editsuppliers from "./Component/Settingspage/Editsuppliers";
import Editsystemsetting from "./Component/Settingspage/Editsystemsetting";
import Createpaymethods from "./Component/Settingspage/Createpaymethods";
import Editpaymethod from "./Component/Settingspage/Editpaymethod";
import Createbill from "./Component/Pages/Createbill";
import ManageQuotation from "./Component/Pages/managequotation";
import Quotationbill from "./Component/Pages/Quotationbill";
import Billquote from "./Component/Pages/Billquote";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem("token")
  );

  useEffect(() => {
    let timeout;

    const resetTimeout = () => {
      clearTimeout(timeout);

      // Set a 10-minute timeout to clear authentication if inactive
      timeout = setTimeout(() => {
        localStorage.removeItem("token");
        setIsAuthenticated(false);
        window.location.href = "/auth";
      }, 600000); // 10 minutes
    };

    if (isAuthenticated) {
      resetTimeout();
    }

    window.addEventListener("mousemove", resetTimeout);
    window.addEventListener("keydown", resetTimeout);

    return () => {
      clearTimeout(timeout);
      window.removeEventListener("mousemove", resetTimeout);
      window.removeEventListener("keydown", resetTimeout);
    };
  }, [isAuthenticated]);

  return (
    <Router>
      <MainApp
        isAuthenticated={isAuthenticated}
        setIsAuthenticated={setIsAuthenticated}
      />
    </Router>
  );
}

function MainApp({ isAuthenticated, setIsAuthenticated }) {
  const location = useLocation();
  const navigate = useNavigate();
  const isAuthPage = location.pathname === "/auth";

  // Redirect to login page if token is missing
  useEffect(() => {
    if (!localStorage.getItem("token") && !isAuthPage) {
      setIsAuthenticated(false);
      navigate("/auth", { replace: true });
    }
  }, [location, isAuthPage, navigate, setIsAuthenticated]);

  const PrivateRoute = ({ element }) => {
    return isAuthenticated ? element : <Navigate to="/auth" replace />;
  };

  return (
    <>
      {!isAuthPage && <Header setIsAuthenticated={setIsAuthenticated} />}
      <Routes>
        <Route
          path="/auth"
          element={<Authform setIsAuthenticated={setIsAuthenticated} />}
        />
        <Route path="/" element={<PrivateRoute element={<Home />} />} />
        <Route path="/Charts" element={<PrivateRoute element={<Charts />} />} />
        <Route path="/Tables" element={<PrivateRoute element={<Tables />} />} />
        <Route path="/Bill" element={<PrivateRoute element={<Bill />} />} />
        <Route
          path="/Billing"
          element={<PrivateRoute element={<Billquote />} />}
        />
        <Route
          path="/Quoatation"
          element={<PrivateRoute element={<Quoatation />} />}
        />
        <Route
          path="/Quotationbill"
          element={<PrivateRoute element={<Quotationbill />} />}
        />
        <Route
          path="/ManageQuotation"
          element={<PrivateRoute element={<ManageQuotation />} />}
        />
        <Route
          path="/ManageCategory"
          element={<PrivateRoute element={<ManageCategory />} />}
        />
        <Route
          path="/ManageInventory"
          element={<PrivateRoute element={<ManageInventory />} />}
        />
        <Route
          path="/ManageCustomer"
          element={<PrivateRoute element={<ManageCustomer />} />}
        />
        <Route
          path="/Manageproduct"
          element={<PrivateRoute element={<Manageproduct />} />}
        />
        <Route
          path="/Addcategory"
          element={<PrivateRoute element={<Addcategory />} />}
        />
        <Route
          path="/AddInventory"
          element={<PrivateRoute element={<AddInventory />} />}
        />
        <Route
          path="/Addcustomer"
          element={<PrivateRoute element={<Addcustomer />} />}
        />
        <Route
          path="/Addproduct"
          element={<PrivateRoute element={<Addproduct />} />}
        />
        <Route
          path="/Allproductlabel"
          element={<PrivateRoute element={<Allproductlabel />} />}
        />
        <Route
          path="/EditCategory/:id"
          element={<PrivateRoute element={<EditCategory />} />}
        />
        <Route
          path="/ProductEdit/:productId"
          element={<PrivateRoute element={<ProductEdit />} />}
        />
        <Route
          path="/UpdateCustomer/:id"
          element={<PrivateRoute element={<UpdateCustomer />} />}
        />
        <Route
          path="/UpdateInventory/:id"
          element={<PrivateRoute element={<UpdateInventory />} />}
        />
        <Route
          path="/Salesreport"
          element={<PrivateRoute element={<Salesreport />} />}
        />
        <Route
          path="/Returnreport"
          element={<PrivateRoute element={<Returnreport />} />}
        />

        {/* Settings pages */}
        <Route
          path="/Outlets"
          element={<PrivateRoute element={<Outlets />} />}
        />
        <Route
          path="/Createoutlet"
          element={<PrivateRoute element={<Createoutlet />} />}
        />
        <Route
          path="/CreateUsers"
          element={<PrivateRoute element={<CreateUsers />} />}
        />
        <Route
          path="/Createsuppliers"
          element={<PrivateRoute element={<Createsuppliers />} />}
        />
        <Route
          path="/Createpaymethods"
          element={<PrivateRoute element={<Createpaymethods />} />}
        />
        <Route
          path="/EditOutlet/:id"
          element={<PrivateRoute element={<EditOutlet />} />}
        />
        <Route
          path="/Edituser/:id"
          element={<PrivateRoute element={<Edituser />} />}
        />
        <Route
          path="/Editsuppliers/:id"
          element={<PrivateRoute element={<Editsuppliers />} />}
        />
        <Route
          path="/Editsystemsetting/:id"
          element={<PrivateRoute element={<Editsystemsetting />} />}
        />
        <Route
          path="/Editpaymethod/:id"
          element={<PrivateRoute element={<Editpaymethod />} />}
        />
        <Route path="/Users" element={<PrivateRoute element={<Users />} />} />
        <Route
          path="/Suppliers"
          element={<PrivateRoute element={<Suppliers />} />}
        />
        <Route
          path="/Systemsettings"
          element={<PrivateRoute element={<Systemsettings />} />}
        />
        <Route
          path="/PaymentMethods"
          element={<PrivateRoute element={<PaymentMethods />} />}
        />
        <Route
          path="/Createbill"
          element={<PrivateRoute element={<Createbill />} />}
        />
        <Route
          path="*"
          element={
            isAuthenticated ? <Navigate to="/" /> : <Navigate to="/auth" />
          }
        />
      </Routes>
      {!isAuthPage && <Footer />}
    </>
  );
}

export default App;
