import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Table,
  Spinner,
  Dropdown,
  Col,
  Row,
  DropdownButton,
} from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import * as XLSX from "xlsx"; // For Excel export

const SalesReport = () => {
  const [outlets, setOutlets] = useState([]);
  const [paidByOptions, setPaidByOptions] = useState([]);
  const [selectedOutlet, setSelectedOutlet] = useState("");
  const [selectedPaidBy, setSelectedPaidBy] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [salesData, setSalesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [visibleColumns, setVisibleColumns] = useState({
    orderId: true,
    dateTime: true,
    paymentMethod: true,
    outlet: true,
    customer: true,
    totalItems: true,
    subtotal: true,
    discount: true,
    grandTotal: true,
  });
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchOutletsAndPaidByOptions = async () => {
      const token = localStorage.getItem("token");
      try {
        const [outletsResponse, paidByResponse] = await Promise.all([
          axios.get("https://luckytraders.bwsoft.in/api/outletlist/", {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get("https://luckytraders.bwsoft.in/api/paymentlist/", {
            headers: { Authorization: `Bearer ${token}` },
          }),
        ]);
        setOutlets(outletsResponse.data);
        setPaidByOptions(paidByResponse.data);
      } catch (error) {
        console.error("Error fetching outlets or payment methods:", error);
        Swal.fire(
          "Error!",
          "Failed to fetch outlets or payment methods.",
          "error"
        );
      }
    };

    const fetchSalesData = async () => {
      const token = localStorage.getItem("token");
      setLoading(true);
      try {
        const response = await axios.get(
          "https://luckytraders.bwsoft.in/api/getAllOrders/",
          {
            headers: { Authorization: `Bearer ${token}` },
            params: {
              outlet: "all", // No filter on outlet
              paid_by: "all", // No filter on payment method
              start_date: "", // No date range filter
              end_date: "", // No date range filter
            },
          }
        );
        setSalesData(response.data);
      } catch (error) {
        console.error("Error fetching sales data:", error);
        setError("Failed to fetch sales data.");
      } finally {
        setLoading(false);
      }
    };

    fetchOutletsAndPaidByOptions();
    fetchSalesData();
  }, []);

  const handleSearch = async () => {
    setLoading(true);
    setError("");

    // Construct the parameters object
    const params = {
      outlet: selectedOutlet || "all", // Default to "all" if no outlet is selected
      paid_by: selectedPaidBy || "all", // Default to "all" if no payment method is selected
    };

    // Only add date parameters if both start and end dates are selected
    if (startDate && endDate) {
      // Ensure both startDate and endDate are in the correct format (YYYY-MM-DD)
      const formattedStartDate = new Date(startDate)
        .toISOString()
        .split("T")[0]; // Get the date part in YYYY-MM-DD format
      const formattedEndDate = new Date(endDate).toISOString().split("T")[0]; // Get the date part in YYYY-MM-DD format

      // Ensure end date is not before start date
      if (new Date(formattedEndDate) < new Date(formattedStartDate)) {
        Swal.fire("Error", "End date cannot be before start date.", "error");
        setLoading(false);
        return;
      }

      params.start_date = formattedStartDate;
      params.end_date = formattedEndDate;
    }

    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        "https://luckytraders.bwsoft.in/api/getAllOrders/",
        {
          headers: { Authorization: `Bearer ${token}` },
          params: params, // Use the constructed params with formatted dates
        }
      );

      // If the response contains no data
      if (response.data && response.data.length === 0) {
        Swal.fire(
          "No Data",
          "No sales data found for the selected criteria.",
          "info"
        );
      }

      setSalesData(response.data);
    } catch (error) {
      console.error("Error fetching sales data:", error);
      setError("Failed to fetch sales data.");
    } finally {
      setLoading(false);
    }
  };

  const filteredSalesData = salesData.filter((sale) => {
    // Filter the sales data based on the selected date range
    let isDateInRange = true;
    if (startDate && endDate) {
      const saleDate = new Date(sale.created_at).toISOString().split("T")[0]; // Format sale date to YYYY-MM-DD
      isDateInRange = saleDate >= startDate && saleDate <= endDate;
    }

    // Filter based on the search term (if any)
    const isSearchMatch = searchTerm
      ? sale.customer_name.toLowerCase().includes(searchTerm.toLowerCase())
      : true;

    return isDateInRange && isSearchMatch;
  });

  const calculateTotals = () => {
    const totalSub = salesData.reduce(
      (sum, sale) => sum + parseFloat(sale.subtotal || 0),
      0
    );
    const totalDiscount = salesData.reduce(
      (sum, sale) => sum + parseFloat(sale.discount_total || 0),
      0
    );
    const grandTotal = totalSub - totalDiscount;

    return { totalSub, totalDiscount, grandTotal };
  };

  const { totalSub, totalDiscount, grandTotal } = calculateTotals();

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return date.toString() !== "Invalid Date" ? date.toLocaleString() : "N/A";
  };

  const toggleColumn = (column) => {
    setVisibleColumns((prev) => ({ ...prev, [column]: !prev[column] }));
  };

  const handleExportToExcel = () => {
    // Use the filteredSalesData instead of salesData to ensure only the filtered data is exported
    const filteredData = filteredSalesData.map((sale) => ({
      OrderID: sale.id,
      DateTime: formatDate(sale.created_at),
      PaymentMethod: sale.payment_method_name,
      Outlet: sale.outlet_name,
      Customer: sale.customer_name,
      TotalItems: sale.total_items,
      Subtotal: parseFloat(sale.subtotal).toFixed(2),
      Discount: parseFloat(sale.discount_total).toFixed(2),
      GrandTotal: parseFloat(sale.grand_total).toFixed(2),
    }));

    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "SalesData");

    // Export to Excel
    XLSX.writeFile(workbook, "Filtered_Sales_Report.xlsx");
  };

  return (
    <div className="container mt-4" id="tabelcontent">
      <h2>Sales Report</h2>

      <Form>
        <Row className="mb-3">
          <Col md={3} xs={12}>
            <Form.Group controlId="formOutlet">
              <Form.Label>Choose Outlet *</Form.Label>
              <Form.Control
                as="select"
                value={selectedOutlet}
                onChange={(e) => setSelectedOutlet(e.target.value)}
                required
              >
                <option value="">-- Select Outlet --</option>
                <option value="all">All</option>
                {outlets.map((outlet) => (
                  <option key={outlet.id} value={outlet.id}>
                    {outlet.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>

          <Col md={3} xs={12}>
            <Form.Group controlId="formPaidBy">
              <Form.Label>Choose Paid By *</Form.Label>
              <Form.Control
                as="select"
                value={selectedPaidBy}
                onChange={(e) => setSelectedPaidBy(e.target.value)}
                required
              >
                <option value="">-- Select Payment Method --</option>
                <option value="all">All</option>
                {paidByOptions.map((method) => (
                  <option key={method.id} value={method.id}>
                    {method.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>

          <Col md={2} xs={12}>
            <Form.Group controlId="formDateRange">
              <Form.Label>Start Date *</Form.Label>
              <Form.Control
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                required
              />
            </Form.Group>
          </Col>

          <Col md={2} xs={12}>
            <Form.Group controlId="formDateRange">
              <Form.Label>End Date *</Form.Label>
              <Form.Control
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                required
              />
            </Form.Group>
          </Col>

          <Col
            md={2}
            xs={12}
            className="d-flex justify-content-center  align-items-center"
          >
            <div className="d-flex justify-content-center excelbtn  align-items-flexend mt-3">
              <Button variant="primary" onClick={handleSearch}>
                Get Report
              </Button>
            </div>
          </Col>
        </Row>
      </Form>

      <div className="mb-3">
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
          <Form.Control
            type="search"
            placeholder="Search by Customer..."
            className="mb-2 mb-md-0 me-md-2 flex-fill"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />

          <div className="d-flex align-items-center mb-2 mb-md-0">
            <DropdownButton
              id="dropdown-basic-button"
              title="Column Filters"
              variant="success"
              className="me-2"
            >
              {Object.keys(visibleColumns).map((column) => (
                <Form.Check
                  key={column}
                  type="checkbox"
                  label={column.replace(/([A-Z])/g, " $1").trim()} // Format column name
                  checked={visibleColumns[column]}
                  onChange={() => toggleColumn(column)}
                />
              ))}
            </DropdownButton>
          </div>
          <Button
            variant="success"
            className="excelbtn"
            onClick={handleExportToExcel}
          >
            Export to Excel
          </Button>
        </div>
      </div>

      {loading ? (
        <div className="d-flex justify-content-center mt-5">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <>
          {error && <div className="alert alert-danger">{error}</div>}

          <Table striped bordered hover responsive>
            <thead>
              <tr>
                {visibleColumns.orderId && <th>Order ID</th>}
                {visibleColumns.dateTime && <th>Date & Time</th>}
                {visibleColumns.paymentMethod && <th>Payment Method</th>}
                {visibleColumns.outlet && <th>Outlet</th>}
                {visibleColumns.customer && <th>Customer</th>}
                {visibleColumns.totalItems && <th>Total Items</th>}
                {visibleColumns.subtotal && <th>Subtotal</th>}
                {visibleColumns.discount && <th>Discount</th>}
                {visibleColumns.grandTotal && <th>Grand Total</th>}
              </tr>
            </thead>
            <tbody>
              {filteredSalesData.map((sale) => (
                <tr key={sale.id}>
                  {visibleColumns.orderId && <td>{sale.id}</td>}
                  {visibleColumns.dateTime && (
                    <td>{formatDate(sale.created_at)}</td>
                  )}
                  {visibleColumns.paymentMethod && (
                    <td>{sale.payment_method_name}</td>
                  )}
                  {visibleColumns.outlet && <td>{sale.outlet_name}</td>}
                  {visibleColumns.customer && <td>{sale.customer_name}</td>}
                  {visibleColumns.totalItems && <td>{sale.total_items}</td>}
                  {visibleColumns.subtotal && <td>{sale.subtotal}</td>}
                  {visibleColumns.discount && <td>{sale.discount_total}</td>}
                  {visibleColumns.grandTotal && <td>{sale.grand_total}</td>}
                </tr>
              ))}
            </tbody>
          </Table>

          <div className="d-flex justify-content-between">
            <div>Total Subtotal: ₹{totalSub.toFixed(2)}</div>
            <div>Total Discount: ₹{totalDiscount.toFixed(2)}</div>
            <div>Grand Total: ₹{grandTotal.toFixed(2)}</div>
          </div>

          <div className="d-flex justify-content-end mt-4">
            <Button onClick={handleExportToExcel}>Export to Excel</Button>
          </div>
        </>
      )}
    </div>
  );
};

export default SalesReport;
