// import { Table, Button, Modal, ListGroup } from "react-bootstrap";
// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// const ManageQuotation = () => {
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [outletList, setOutletList] = useState([]);
//   const [selectedOutlet, setSelectedOutlet] = useState(null);
//   const [quotations, setQuotations] = useState([]); // State to store quotations
//   const navigate = useNavigate();
//   useEffect(() => {
//     const token = localStorage.getItem("token");
//     const config = {
//       headers: {
//         Authorization: `Bearer ${token}`,
//         "Content-Type": "application/json",
//       },
//     };

//     // Fetch outlet list
//     axios
//       .get("https://luckytraders.bwsoft.in/api/outletlist/", config)
//       .then((response) => {
//         if (Array.isArray(response.data)) {
//           setOutletList(response.data);
//         }
//       })
//       .catch((error) => console.error("Error fetching outlets:", error));

//     // Open the modal when the component mounts
//     setIsModalOpen(true);
//   }, []);

//   // Function to fetch quotations for a specific outlet
//   const fetchQuotations = (outletId) => {
//     const token = localStorage.getItem("token");
//     const config = {
//       headers: {
//         Authorization: `Bearer ${token}`,
//         "Content-Type": "application/json",
//       },
//     };

//     // Fetch quotations from API for selected outlet
//     axios
//       .get(
//         `https://luckytraders.bwsoft.in/api/quotations-outlet/${outletId}`,
//         config
//       )
//       .then((response) => {
//         // Reverse the quotations list before setting state
//         setQuotations(response.data.reverse()); // Reverse the data
//       })
//       .catch((error) => {
//         console.error("Error fetching quotations:", error);
//       });
//   };

//   const handleOpenModal = () => setIsModalOpen(true);
//   const handleCloseModal = () => setIsModalOpen(false);

//   const handleSelectOutlet = (outlet) => {
//     setSelectedOutlet(outlet); // Set the entire outlet object
//     localStorage.setItem("selectedOutletId", outlet.id); // Optionally store in localStorage
//     fetchQuotations(outlet.id); // Fetch quotations for the selected outlet
//     handleCloseModal();
//   };

//   const handleSubmit = () => {
//     const token = localStorage.getItem("token");
//     const outletId = selectedOutlet ? selectedOutlet.id : null;

//     if (!outletId) {
//       alert("Please select an outlet.");
//       return;
//     }

//     const config = {
//       headers: {
//         Authorization: `Bearer ${token}`,
//         "Content-Type": "application/json",
//       },
//     };

//     axios
//       .post(`https://luckytraders.bwsoft.in/api/quotations/`, config)
//       .then((response) => {
//         alert("Quotation created successfully!");
//         console.log("Response:", response.data);
//       })
//       .catch((error) => {
//         console.error("Error creating quotation:", error);
//         alert("Failed to create quotation.");
//       });
//   };

//   const formatDate = (dateString) => {
//     const date = new Date(dateString);
//     const day = ("0" + date.getDate()).slice(-2);
//     const month = ("0" + (date.getMonth() + 1)).slice(-2);
//     const year = date.getFullYear();
//     return `${day}/${month}/${year}`;
//   };

//   const handleCreateBill = (quotationId) => {
//     const postData = { quotationId };
//     navigate(`/Billing`, { state: { quotationData: postData } });
//   };

//   return (
//     <div className="bill-template" id="tabelcontent">
//       <h1 className="text-center">Manage Quotation</h1>
//       <div className="bill-header">
//         <div>
//           <p>
//             Outlet:{" "}
//             <span style={{ fontSize: "25px" }}>
//               {selectedOutlet ? selectedOutlet.name : "No outlet selected"}
//             </span>
//           </p>
//         </div>
//         <div>
//           <Button onClick={handleOpenModal}>Change Outlet</Button>
//         </div>
//       </div>

//       {/* Quotation Table */}
//       <Table striped bordered hover className="bill-table">
//         <thead>
//           <tr>
//             <th>#</th>
//             <th>Quotation id</th>
//             <th>Customer Name</th>

//             <th>Date</th>

//             <th>Total Price</th>
//             <th>create bill</th>
//           </tr>
//         </thead>
//         <tbody>
//           {quotations.length > 0 ? (
//             quotations.map((quotation, index) => {
//               console.log(quotation); // Log to inspect data
//               return (
//                 <tr key={quotation.id}>
//                   <td>{index + 1}</td>
//                   <td>{quotation.id}</td>
//                   <td>{quotation.customer?.name}</td>
//                   <td>
//                     {" "}
//                     {formatDate(quotation.created_at)} {/* Format date */}
//                   </td>

//                   <td>₹{parseFloat(quotation.total_price).toFixed(2)}</td>
//                   <td>
//                     <Button
//                       variant="primary"
//                       onClick={() => handleCreateBill(quotation.id)}
//                     >
//                       Create Bill
//                     </Button>
//                   </td>
//                 </tr>
//               );
//             })
//           ) : (
//             <tr>
//               <td colSpan="8" className="text-center">
//                 No quotations available for this outlet.
//               </td>
//             </tr>
//           )}
//         </tbody>
//       </Table>

//       <Modal show={isModalOpen} onHide={handleCloseModal} centered>
//         <Modal.Header closeButton>
//           <Modal.Title>Select Outlet</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <ListGroup>
//             {outletList.map((outlet) => (
//               <ListGroup.Item
//                 key={outlet.id}
//                 action
//                 onClick={() => handleSelectOutlet(outlet)}
//                 className="d-flex flex-column justify-content-center align-items-center"
//               >
//                 <strong>{outlet.name}</strong>
//                 <div>{outlet.address}</div>
//                 <div>Mobile: {outlet.mobile}</div>
//               </ListGroup.Item>
//             ))}
//           </ListGroup>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleCloseModal}>
//             Close
//           </Button>
//         </Modal.Footer>
//       </Modal>

//       {/* Additional Styling */}
//       <style jsx>{`
//         .product-dropdown {
//           list-style: none;
//           padding: 0;
//           margin: 0;
//           border: 1px solid #ccc;
//           max-height: 100px;
//           overflow-y: auto;
//           position: absolute;
//           background-color: white;
//           z-index: 10;
//           width: calc(30% - 20px);
//         }

//         .dropdown-item {
//           padding: 8px 12px;
//           cursor: pointer;
//         }

//         .dropdown-item:hover {
//           background-color: #f1f1f1;
//         }

//         .bill-table {
//           margin-top: 20px;
//         }

//         .customer-info {
//           margin-bottom: 20px;
//         }
//       `}</style>
//     </div>
//   );
// };

// export default ManageQuotation;

// import { Table, Button, Modal, ListGroup, Form } from "react-bootstrap";
// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";

// const ManageQuotation = () => {
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [outletList, setOutletList] = useState([]);
//   const [selectedOutlet, setSelectedOutlet] = useState(null);
//   const [quotations, setQuotations] = useState([]); // State to store quotations
//   const [searchQuery, setSearchQuery] = useState(""); // State to store search query
//   const [filteredQuotations, setFilteredQuotations] = useState([]); // State to store filtered quotations
//   const navigate = useNavigate();

//   useEffect(() => {
//     const token = localStorage.getItem("token");
//     const config = {
//       headers: {
//         Authorization: `Bearer ${token}`,
//         "Content-Type": "application/json",
//       },
//     };

//     // Fetch outlet list
//     axios
//       .get("https://luckytraders.bwsoft.in/api/outletlist/", config)
//       .then((response) => {
//         if (Array.isArray(response.data)) {
//           setOutletList(response.data);
//         }
//       })
//       .catch((error) => console.error("Error fetching outlets:", error));

//     // Open the modal when the component mounts
//     setIsModalOpen(true);
//   }, []);

//   // Function to fetch quotations for a specific outlet
//   const fetchQuotations = (outletId) => {
//     const token = localStorage.getItem("token");
//     const config = {
//       headers: {
//         Authorization: `Bearer ${token}`,
//         "Content-Type": "application/json",
//       },
//     };

//     // Fetch quotations from API for selected outlet
//     axios
//       .get(
//         `https://luckytraders.bwsoft.in/api/quotations-outlet/${outletId}`,
//         config
//       )
//       .then((response) => {
//         // Reverse the quotations list before setting state
//         setQuotations(response.data.reverse()); // Reverse the data
//         setFilteredQuotations(response.data.reverse()); // Also set filtered quotations
//       })
//       .catch((error) => {
//         console.error("Error fetching quotations:", error);
//       });
//   };

//   const handleOpenModal = () => setIsModalOpen(true);
//   const handleCloseModal = () => setIsModalOpen(false);

//   const handleSelectOutlet = (outlet) => {
//     setSelectedOutlet(outlet); // Set the entire outlet object
//     localStorage.setItem("selectedOutletId", outlet.id); // Optionally store in localStorage
//     fetchQuotations(outlet.id); // Fetch quotations for the selected outlet
//     handleCloseModal();
//   };

//   const handleSubmit = () => {
//     const token = localStorage.getItem("token");
//     const outletId = selectedOutlet ? selectedOutlet.id : null;

//     if (!outletId) {
//       alert("Please select an outlet.");
//       return;
//     }

//     const config = {
//       headers: {
//         Authorization: `Bearer ${token}`,
//         "Content-Type": "application/json",
//       },
//     };

//     axios
//       .post(`https://luckytraders.bwsoft.in/api/quotations/`, config)
//       .then((response) => {
//         alert("Quotation created successfully!");
//         console.log("Response:", response.data);
//       })
//       .catch((error) => {
//         console.error("Error creating quotation:", error);
//         alert("Failed to create quotation.");
//       });
//   };

//   const formatDate = (dateString) => {
//     const date = new Date(dateString);
//     const day = ("0" + date.getDate()).slice(-2);
//     const month = ("0" + (date.getMonth() + 1)).slice(-2);
//     const year = date.getFullYear();
//     return `${day}/${month}/${year}`;
//   };

//   const handleCreateBill = (quotationId) => {
//     const postData = { quotationId };
//     navigate(`/Billing`, { state: { quotationData: postData } });
//   };

//   // Filter quotations based on search query
//   const handleSearch = (event) => {
//     const query = event.target.value;
//     setSearchQuery(query);

//     // Filter quotations based on the search query
//     const filtered = quotations.filter((quotation) => {
//       const quotationIdMatch = quotation.id.toString().includes(query);
//       const customerNameMatch = quotation.customer?.name
//         .toLowerCase()
//         .includes(query.toLowerCase());
//       const dateMatch = formatDate(quotation.created_at).includes(query);
//       const totalPriceMatch = quotation.total_price.toString().includes(query);

//       return (
//         quotationIdMatch || customerNameMatch || dateMatch || totalPriceMatch
//       );
//     });

//     setFilteredQuotations(filtered);
//   };

//   return (
//     <div className="bill-template" id="tabelcontent">
//       <h1 className="text-center">Manage Quotation</h1>
//       <div className="bill-header">
//         <div>
//           <p>
//             Outlet:{" "}
//             <span style={{ fontSize: "25px" }}>
//               {selectedOutlet ? selectedOutlet.name : "No outlet selected"}
//             </span>
//           </p>
//         </div>
//         <div>
//           <Button onClick={handleOpenModal}>Change Outlet</Button>
//         </div>
//       </div>

//       {/* Search Input */}
//       <div className="mb-4">
//         <Form.Control
//           type="text"
//           placeholder="Search Quotation id, Customer Name, Date, Total Price"
//           value={searchQuery}
//           onChange={handleSearch}
//         />
//       </div>

//       {/* Quotation Table */}
//       <Table striped bordered hover className="bill-table">
//         <thead>
//           <tr>
//             <th>#</th>
//             <th>Quotation id</th>
//             <th>Customer Name</th>
//             <th>Date</th>
//             <th>Total Price</th>
//             <th>create bill</th>
//           </tr>
//         </thead>
//         <tbody>
//           {filteredQuotations.length > 0 ? (
//             filteredQuotations.map((quotation, index) => {
//               console.log(quotation); // Log to inspect data
//               return (
//                 <tr key={quotation.id}>
//                   <td>{index + 1}</td>
//                   <td>{quotation.id}</td>
//                   <td>{quotation.customer?.name}</td>
//                   <td>{formatDate(quotation.created_at)}</td>
//                   <td>₹{parseFloat(quotation.total_price).toFixed(2)}</td>
//                   <td>
//                     <Button
//                       variant="primary"
//                       onClick={() => handleCreateBill(quotation.id)}
//                     >
//                       Create Bill
//                     </Button>
//                   </td>
//                 </tr>
//               );
//             })
//           ) : (
//             <tr>
//               <td colSpan="6" className="text-center">
//                 No quotations available for this outlet.
//               </td>
//             </tr>
//           )}
//         </tbody>
//       </Table>

//       <Modal show={isModalOpen} onHide={handleCloseModal} centered>
//         <Modal.Header closeButton>
//           <Modal.Title>Select Outlet</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <ListGroup>
//             {outletList.map((outlet) => (
//               <ListGroup.Item
//                 key={outlet.id}
//                 action
//                 onClick={() => handleSelectOutlet(outlet)}
//                 className="d-flex flex-column justify-content-center align-items-center"
//               >
//                 <strong>{outlet.name}</strong>
//                 <div>{outlet.address}</div>
//                 <div>Mobile: {outlet.mobile}</div>
//               </ListGroup.Item>
//             ))}
//           </ListGroup>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleCloseModal}>
//             Close
//           </Button>
//         </Modal.Footer>
//       </Modal>

//       {/* Additional Styling */}
//       <style jsx>{`
//         .bill-table {
//           margin-top: 20px;
//         }
//         .customer-info {
//           margin-bottom: 20px;
//         }
//       `}</style>
//     </div>
//   );
// };

// export default ManageQuotation;

import { Table, Button, Modal, ListGroup, Form } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ManageQuotation = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [outletList, setOutletList] = useState([]);
  const [selectedOutlet, setSelectedOutlet] = useState(null);
  const [quotations, setQuotations] = useState([]); // State to store quotations
  const [searchQuery, setSearchQuery] = useState(""); // State to store search query
  const [filteredQuotations, setFilteredQuotations] = useState([]); // State to store filtered quotations
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    // Fetch outlet list
    axios
      .get("https://luckytraders.bwsoft.in/api/outletlist/", config)
      .then((response) => {
        if (Array.isArray(response.data)) {
          setOutletList(response.data);
        }
      })
      .catch((error) => console.error("Error fetching outlets:", error));

    // Open the modal when the component mounts
    setIsModalOpen(true);
  }, []);

  // Function to fetch quotations for a specific outlet
  const fetchQuotations = (outletId) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    // Fetch quotations from API for selected outlet
    axios
      .get(
        `https://luckytraders.bwsoft.in/api/quotations-outlet/${outletId}`,
        config
      )
      .then((response) => {
        const reversedQuotations = response.data.reverse(); // Reverse the data
        setQuotations(reversedQuotations); // Store the reversed data
        setFilteredQuotations(reversedQuotations); // Also set filtered quotations as reversed
      })
      .catch((error) => {
        console.error("Error fetching quotations:", error);
      });
  };

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleSelectOutlet = (outlet) => {
    setSelectedOutlet(outlet); // Set the entire outlet object
    localStorage.setItem("selectedOutletId", outlet.id); // Optionally store in localStorage
    fetchQuotations(outlet.id); // Fetch quotations for the selected outlet
    handleCloseModal();
  };

  const handleSubmit = () => {
    const token = localStorage.getItem("token");
    const outletId = selectedOutlet ? selectedOutlet.id : null;

    if (!outletId) {
      alert("Please select an outlet.");
      return;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    axios
      .post(`https://luckytraders.bwsoft.in/api/quotations/`, config)
      .then((response) => {
        alert("Quotation created successfully!");
        console.log("Response:", response.data);
      })
      .catch((error) => {
        console.error("Error creating quotation:", error);
        alert("Failed to create quotation.");
      });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleCreateBill = (quotationId) => {
    const postData = { quotationId };
    navigate(`/Billing`, { state: { quotationData: postData } });
  };

  // Filter quotations based on search query
  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    // Filter quotations based on the search query
    const filtered = quotations.filter((quotation) => {
      const quotationIdMatch = quotation.id.toString().includes(query);
      const customerNameMatch = quotation.customer?.name
        .toLowerCase()
        .includes(query.toLowerCase());
      const dateMatch = formatDate(quotation.created_at).includes(query);
      const totalPriceMatch = quotation.total_price.toString().includes(query);

      return (
        quotationIdMatch || customerNameMatch || dateMatch || totalPriceMatch
      );
    });

    setFilteredQuotations(filtered.reverse()); // Reverse the filtered results
  };

  return (
    <div className="bill-template" id="tabelcontent">
      <h1 className="text-center">Manage Quotation</h1>
      <div className="bill-header">
        <div>
          <p>
            Outlet:{" "}
            <span style={{ fontSize: "25px" }}>
              {selectedOutlet ? selectedOutlet.name : "No outlet selected"}
            </span>
          </p>
        </div>
        <div>
          <Button onClick={handleOpenModal}>Change Outlet</Button>
        </div>
      </div>

      {/* Search Input */}
      <div className="mb-4">
        <Form.Control
          type="text"
          placeholder="Search Quotation id, Customer Name, Date, Total Price"
          value={searchQuery}
          onChange={handleSearch}
        />
      </div>

      {/* Quotation Table */}
      <Table striped bordered hover className="bill-table">
        <thead>
          <tr>
            <th>#</th>
            <th>Quotation id</th>
            <th>Customer Name</th>
            <th>Date</th>
            <th>Total Price</th>
            <th>create bill</th>
          </tr>
        </thead>
        <tbody>
          {filteredQuotations.length > 0 ? (
            filteredQuotations.map((quotation, index) => {
              return (
                <tr key={quotation.id}>
                  <td>{index + 1}</td>
                  <td>{quotation.id}</td>
                  <td>{quotation.customer?.name}</td>
                  <td>{formatDate(quotation.created_at)}</td>
                  <td>₹{parseFloat(quotation.total_price).toFixed(2)}</td>
                  <td>
                    <Button
                      variant="primary"
                      onClick={() => handleCreateBill(quotation.id)}
                    >
                      Create Bill
                    </Button>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="6" className="text-center">
                No quotations available for this outlet.
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <Modal show={isModalOpen} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Select Outlet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup>
            {outletList.map((outlet) => (
              <ListGroup.Item
                key={outlet.id}
                action
                onClick={() => handleSelectOutlet(outlet)}
                className="d-flex flex-column justify-content-center align-items-center"
              >
                <strong>{outlet.name}</strong>
                <div>{outlet.address}</div>
                <div>Mobile: {outlet.mobile}</div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Additional Styling */}
      <style jsx>{`
        .bill-table {
          margin-top: 20px;
        }
        .customer-info {
          margin-bottom: 20px;
        }
      `}</style>
    </div>
  );
};

export default ManageQuotation;
