import React, { useEffect, useState } from "react";
import { Button, Table, Form, InputGroup, Spinner } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "bootstrap/dist/css/bootstrap.min.css";

const ManageInventory = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [inventory, setInventory] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); // Initialize useNavigate

  const fetchInventory = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.get(
        "https://luckytraders.bwsoft.in/api/inventorylist/",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setInventory(response.data);
    } catch (error) {
      console.error("Error fetching inventory:", error);
      Swal.fire({
        title: "Error!",
        text: "Failed to fetch inventory data.",
        icon: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInventory();
  }, []);

  const handleDelete = async (itemId) => {
    const token = localStorage.getItem("token");

    const confirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirm.isConfirmed) {
      try {
        await axios.delete(
          `https://luckytraders.bwsoft.in/api/inventory/delete/${itemId}/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Update the inventory state to remove the deleted item
        setInventory(inventory.filter((item) => item.id !== itemId));
        Swal.fire("Deleted!", "Your item has been deleted.", "success");
      } catch (error) {
        console.error("Error deleting item:", error);
        Swal.fire("Error!", "Failed to delete item.", "error");
      }
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleExportToExcel = () => {
    alert("Export to Excel functionality will go here");
  };

  return (
    <div className="container mt-4" id="tabelcontent">
      <div className="container d-flex flex-column flex-md-row align-items-center justify-content-between">
        <h2>Manage Inventory</h2>

        <div className="mb-3 d-flex flex-row flex-md-row gap-2">
          <Button variant="primary" className="mb-2 mb-md-0 me-md-2">
            Add Inventory
          </Button>
          <Button
            variant="success"
            className="mb-2 mb-md-0 me-md-2"
            onClick={handleExportToExcel}
          >
            Export to Excel
          </Button>
        </div>
      </div>

      <InputGroup className="mb-3">
        <Form.Control
          type="text"
          placeholder="Search Inventory..."
          value={searchTerm}
          onChange={handleSearch}
        />
      </InputGroup>

      {loading ? (
        <div className="text-center">
          <Spinner animation="border" />
          <p>Loading...</p>
        </div>
      ) : (
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Product Name</th>
              <th>Outlet Name</th>
              <th>Quantity</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {inventory
              .filter(
                (item) =>
                  item.product_name
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) ||
                  item.outlet_name
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
              )
              .map((item) => (
                <tr key={item.id}>
                  <td>{item.product_name}</td>
                  <td>{item.outlet_name}</td>
                  <td>{item.qty}</td>
                  <td>
                    <Button
                      variant="warning"
                      size="sm"
                      className="me-2"
                      onClick={() =>
                        navigate(
                          `/UpdateInventory/${item.id}?productId=${item.product_id}&outletId=${item.outlet_id}`
                        )
                      }
                    >
                      Edit
                    </Button>

                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => handleDelete(item.id)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default ManageInventory;
