import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap"; // Import necessary components
import Swal from "sweetalert2"; // For alert dialogs
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import { useParams, useNavigate } from "react-router-dom"; // For routing

const Editsuppliers = () => {
  const { id } = useParams(); // Get supplier ID from URL
  const navigate = useNavigate(); // For navigation
  const [supplier, setSupplier] = useState({
    name: "",
    email: "",
    mobile: "",
    address: "",
    tax: "",
  });
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchSupplier = async () => {
      const token = localStorage.getItem("token"); // Retrieve access token

      try {
        const response = await fetch(
          `https://luckytraders.bwsoft.in/api/supplier/update/${id}/`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) throw new Error("Failed to fetch supplier data");

        const data = await response.json();
        setSupplier(data); // Populate the form with fetched supplier data
      } catch (error) {
        console.error("Error fetching supplier:", error);
        Swal.fire("Error!", "Failed to fetch supplier data.", "error");
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchSupplier(); // Call the function to fetch supplier data
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSupplier((prevSupplier) => ({
      ...prevSupplier,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token"); // Retrieve access token

    try {
      const response = await fetch(
        `https://luckytraders.bwsoft.in/api/supplier/update/${id}/`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(supplier),
        }
      );

      if (response.ok) {
        Swal.fire("Success!", "Supplier updated successfully.", "success");
        navigate("/suppliers"); // Redirect to suppliers list after successful update
      } else {
        throw new Error("Failed to update supplier");
      }
    } catch (error) {
      console.error("Error updating supplier:", error);
      Swal.fire("Error!", "Failed to update the supplier.", "error");
    }
  };

  if (loading) {
    return <div className="text-center">Loading supplier data...</div>; // Loading message for supplier data
  }

  return (
    <div className="container mt-4" id="tabelcontent">
      <h1>Edit Supplier</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formName">
          <Form.Label>Supplier Name *</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={supplier.name}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="formEmail">
          <Form.Label>Email Address *</Form.Label>
          <Form.Control
            type="email"
            name="email"
            value={supplier.email}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="formMobile">
          <Form.Label>Contact *</Form.Label>
          <Form.Control
            type="text"
            name="mobile"
            value={supplier.mobile}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="formAddress">
          <Form.Label>Address *</Form.Label>
          <Form.Control
            type="text"
            name="address"
            value={supplier.address}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="formTax">
          <Form.Label>Tax (%) *</Form.Label>
          <Form.Control
            type="number"
            name="tax"
            value={supplier.tax}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Button variant="primary" type="submit" className="mt-3">
          Update Supplier
        </Button>
      </Form>
    </div>
  );
};

export default Editsuppliers;
