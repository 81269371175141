import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";

const Authform = ({ setIsAuthenticated }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSignInClick = async () => {
    setError("");
    setLoading(true);

    try {
      const response = await axios.post(
        "https://luckytraders.bwsoft.in/api/admin/login/",
        { email, password },
        { headers: { "Content-Type": "application/json" } }
      );

      const token = response.data.accessToken;
      localStorage.setItem("token", token);
      localStorage.setItem("isAuthenticated", "true");
      setIsAuthenticated(true);

      Swal.fire({
        title: "Success!",
        text: "You have logged in successfully.",
        icon: "success",
        confirmButtonText: "OK",
      }).then(() => navigate("/"));
    } catch (error) {
      setError("Login failed. Please check your credentials.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="auth-container">
      <div className="auth-box">
        <img src="assets/img/logo.jpeg" alt="Logo" className="auth-logo" />
        <h2>Welcome Back</h2>

        <form>
          <div className="auth-input">
            <input
              type="email"
              placeholder="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="auth-input">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <span
              className="toggle-password"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
          {error && <p className="auth-error">{error}</p>}
          <button
            type="button"
            onClick={handleSignInClick}
            disabled={loading}
            className="auth-button"
          >
            {loading ? "Logging in..." : "Log In"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Authform;
