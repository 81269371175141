import { Table, Button, Modal, ListGroup, Form } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Bill = () => {
  const [invoiceDetails, setInvoiceDetails] = useState({
    items: [],
    discountRate: 0, // default discount is 0
  });

  const [productList, setProductList] = useState([]);
  const [filteredProductList, setFilteredProductList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [outletList, setOutletList] = useState([]);
  const [selectedOutlet, setSelectedOutlet] = useState(null);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

  const [paymentMethod, setPaymentMethod] = useState("Cash");
  const [paidAmount, setPaidAmount] = useState(0);
  const [returnChange, setReturnChange] = useState(0);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [settinglist, setSettinglist] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    // Fetch product list
    axios
      .get("https://luckytraders.bwsoft.in/api/productlist/", config)
      .then((response) => {
        if (Array.isArray(response.data)) {
          setProductList(response.data);
          setFilteredProductList(response.data);
        }
      })
      .catch((error) => console.error("Error fetching products:", error));

    // Fetch outlet list
    axios
      .get("https://luckytraders.bwsoft.in/api/outletlist/", config)
      .then((response) => {
        if (Array.isArray(response.data)) {
          setOutletList(response.data);
        }
      })
      .catch((error) => console.error("Error fetching outlets:", error));

    axios
      .get("https://luckytraders.bwsoft.in/api/paymentlist/", config)
      .then((response) => {
        if (Array.isArray(response.data)) {
          setPaymentMethods(response.data.filter((method) => method.status));
          setPaymentMethod(response.data[0]?.name || "Cash"); // Set default payment method
        }
      })
      .catch((error) =>
        console.error("Error fetching payment methods:", error)
      );

    axios
      .get("https://luckytraders.bwsoft.in/api/customerlist/", config)
      .then((response) => {
        if (Array.isArray(response.data)) {
          setCustomerList(response.data);
        }
      })
      .catch((error) => console.error("Error fetching customers:", error));
    // Open the modal when the component mounts
    setIsModalOpen(true);
  }, []);

  useEffect(() => {
    const fetchSettingList = async () => {
      const token = localStorage.getItem("token");

      try {
        const response = await fetch(
          "https://luckytraders.bwsoft.in/api/settinglist/",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) throw new Error("Failed to fetch settings");

        const data = await response.json();
        console.log(data); // Log to check the structure
        setSettinglist(Array.isArray(data) ? data : []); // Ensure it's an array
      } catch (error) {
        console.error("Error fetching setting list:", error);
      }
    };

    fetchSettingList();
  }, []);

  const handleSearchChange = (event) => {
    const searchValue = event.target.value;
    setSearchTerm(searchValue);
    const filteredProducts = productList.filter(
      (product) =>
        product.name.toLowerCase().includes(searchValue.toLowerCase()) ||
        product.code.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredProductList(filteredProducts);
  };

  const handleSelectProduct = (product) => {
    const newItem = {
      id: product.id,
      name: product.name,
      gst: product.gst, // This will come from the backend
      igst: product.igst, // This will come from the backend
      code: product.code || "N/A",
      price: parseFloat(product.retail_price) || 0,
      quantity: 1,
    };

    setInvoiceDetails((prevDetails) => ({
      ...prevDetails,
      items: [...prevDetails.items, newItem],
    }));

    setSearchTerm("");
    setFilteredProductList(productList); // Reset filtered product list
  };

  const handleQuantityChange = (itemId, amount) => {
    setInvoiceDetails((prevDetails) => ({
      ...prevDetails,
      items: prevDetails.items.map((item) =>
        item.id === itemId
          ? { ...item, quantity: Math.max(1, item.quantity + amount) }
          : item
      ),
    }));
  };
  const handleDiscountChange = (e) => {
    const newDiscountRate = parseFloat(e.target.value) || 0; // Make sure to handle invalid inputs
    setInvoiceDetails((prevDetails) => ({
      ...prevDetails,
      discountRate: newDiscountRate,
    }));
  };

  const subtotal = invoiceDetails.items.reduce(
    (acc, item) => acc + Number(item.price || 0) * Number(item.quantity || 0),
    0
  );

  const totalGst = invoiceDetails.items.reduce(
    (acc, item) => acc + ((item.gst * item.price * item.quantity) / 100 || 0),
    0
  );
  const totalIgst = invoiceDetails.items.reduce(
    (acc, item) => acc + ((item.igst * item.price * item.quantity) / 100 || 0),
    0
  );

  const discountAmount = (subtotal * invoiceDetails.discountRate) / 100;
  const totalPayable = subtotal + totalIgst + totalGst - discountAmount;

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleSelectOutlet = (outlet) => {
    setSelectedOutlet(outlet); // Set the entire outlet object
    localStorage.setItem("selectedOutletId", outlet.id); // Optionally store in localStorage
    handleCloseModal();
  };

  const handleOpenPaymentModal = () => {
    setIsPaymentModalOpen(true);
  };

  const navigate = useNavigate(); // Use useNavigate inside the component

  // Handle make payment function
  const handleMakePayment = (
    selectedOutlet,
    selectedCustomer,
    paymentMethod,
    totalPayable,
    paidAmount,
    invoiceDetails,
    selectedAccount
  ) => {
    console.log("Payment Details Received:");
    console.log("Selected Outlet:", selectedOutlet);
    console.log("Selected Customer:", selectedCustomer);
    console.log("Payment Method:", paymentMethod);
    console.log("Total Payable:", totalPayable);
    console.log("Paid Amount:", paidAmount);
    console.log("Invoice Details:", invoiceDetails);
    console.log("Selected Account:", selectedAccount);
    // Ensure you have the correct selected values
    if (!selectedOutlet || !selectedCustomer || !paymentMethod) {
      alert("Please select an outlet, customer, and payment method.");
      return;
    }
    const gst_charge = totalGst.toFixed(2);
    const igst_charge = totalIgst.toFixed(2);

    const orderData = {
      card_number: "", // Leave empty if not applicable
      cgst_charge: "0.00", // Use string as required
      cgst_percent: "0.00", // Use string as required
      cheque_number: "", // Leave empty if not applicable
      created_by: 1, // Adjust as needed
      customer_id: selectedCustomer,
      // customer_id: String(selectedCustomer), // Ensure this is a string
      discount_percentage: "0.00", // Use string as required
      discount_total: discountAmount.toFixed(2), // Use string as required
      grand_total: totalPayable.toFixed(2), // Ensure this is a string
      igst_charge: igst_charge, // String format
      gst_charge: gst_charge,

      // Use string as required
      order_items: invoiceDetails.items.map((item) => ({
        igst_percent: "0.00",
        gst_percent: "0.00",
        product_id: item.id, // Ensure this is correct
        product_name: item.name,
        product_code: item.code,
        qty: item.quantity, // Ensure this is a number
        category_id: item.categoryId || 0, // Ensure this is a number
        purchase_price: item.purchasePrice || "0.00", // Ensure this is included and is a string
        retail_price: item.price || "0.00", // Include retail_price and ensure it's a string
      })),
      outlet_id: selectedOutlet.id, // Ensure this is set and is a number
      paid_amount: paidAmount, // Ensure this is a string
      payment_id: parseInt(paymentMethod, 10), // Ensure this is a number
      refund_status: 0, // Assuming this is a fixed value
      remark: "", // Leave empty if not applicable
      return_change: (paidAmount - totalPayable).toFixed(2), // Calculate return change
      sgst_charge: "0.00", // Use string as required
      sgst_percent: "0.00", // Use string as required
      status: 1, // Assuming this is a fixed value
      subtotal: subtotal.toFixed(2), // Ensure this is a string
      total_items: invoiceDetails.items.length, // This should match the length of order_items
      updated_by: null, // Can leave empty or set to null
      vt_status: 1, // Assuming this is a fixed value
      selected_account: selectedAccount, // Pass the selected account here
    };

    // Get the token from localStorage
    const token = localStorage.getItem("token");

    // Set up the request config with headers
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    axios
      .post(
        "https://luckytraders.bwsoft.in/api/create_order/",
        orderData,
        config
      )
      .then((response) => {
        console.log("Order created successfully:", response.data);
        console.log("Selected Account:", selectedAccount);
        navigate("/Createbill", {
          state: { orderDetails: response.data, selectedAccount },
        });
      })
      .catch((error) => {
        console.error("Error creating order:", error.response.data);
      });
  };
  const handleRemoveItem = (id) => {
    setInvoiceDetails((prevDetails) => ({
      ...prevDetails,
      items: prevDetails.items.filter((item) => item.id !== id),
    }));
  };
  return (
    <div className="bill-template" id="tabelcontent">
      <h1 className="text-center">Invoice</h1>
      <div className="bill-header">
        <div className="product-search">
          <div
            style={{ position: "relative", width: "100%", maxWidth: "400px" }}
          >
            <input
              type="text"
              id="product-search"
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder="Type product name or code..."
              style={{
                width: "100%",
                padding: "10px 40px 10px 15px", // Padding for space around the icon
                border: "2px solid #ddd",
                borderRadius: "8px",
                fontSize: "16px",
              }}
            />
            <span
              style={{
                position: "absolute",
                right: "15px",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
                color: "#999",
              }}
            >
              🔍{" "}
              {/* Search Icon - you can replace this with a search icon from a library */}
            </span>
          </div>

          {searchTerm && filteredProductList.length > 0 && (
            <ul className="product-dropdown">
              {filteredProductList.map((product) => (
                <li
                  key={product.id}
                  onClick={() => handleSelectProduct(product)}
                  className="dropdown-item"
                >
                  {product.name} - ₹{product.retail_price || 0}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div>
          {" "}
          <p>
            Outlet:{" "}
            <span style={{ fontSize: "25px" }}>
              {selectedOutlet ? selectedOutlet.name : "No outlet selected"}
            </span>
          </p>
        </div>
        <div>
          {" "}
          <Button onClick={handleOpenModal}>Change Outlet</Button>
        </div>
      </div>
      <Table striped bordered hover className="bill-table">
        <thead>
          <tr>
            <th>#</th>
            <th>Product Name</th>
            <th>HSN Code</th>
            <th>Quantity</th>
            <th>Price</th>
            <th>Gst</th>
            <th>IGst</th>
            <th>Total</th>
            <th>Remove</th>
          </tr>
        </thead>
        <tbody>
          {invoiceDetails.items.map((item, index) => (
            <tr key={item.id}>
              <td>{index + 1}</td>
              <td>
                {item.name}
                {item.quantity <= 0 && (
                  <div style={{ color: "red", fontSize: "0.9em" }}>
                    Error: No quantity available
                  </div>
                )}
              </td>
              <td>{item.code}</td>
              <td>
                <div className="quantity-control">
                  <button
                    className="quantity-button"
                    onClick={() => handleQuantityChange(item.id, -1)}
                  >
                    -
                  </button>
                  <input
                    type="number"
                    value={item.quantity}
                    onChange={(e) =>
                      handleQuantityChange(item.id, e.target.value)
                    }
                    min="0"
                    className="quantity-input"
                  />
                  <button
                    className="quantity-button"
                    onClick={() => handleQuantityChange(item.id, 1)}
                  >
                    +
                  </button>
                </div>
              </td>
              <td>₹{item.price.toFixed(2)}</td>
              <td>{item.gst ? `${item.gst}%` : "0%"}</td>
              <td>{item.igst ? `${item.igst}%` : "0%"}</td>
              <td>
                ₹
                {(
                  item.price * item.quantity +
                  (item.price * item.quantity * (item.gst || 0)) / 100 +
                  (item.price * item.quantity * (item.igst || 0)) / 100
                ).toFixed(2)}
              </td>
              <td>
                <button
                  className="btn btn-danger"
                  onClick={() => handleRemoveItem(item.id)}
                >
                  X
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Table striped bordered hover className="custom-bill-summary-table">
        <thead>
          <tr>
            <th colSpan={4} className="table-title">
              Bill Summary
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="label">Total Purchase Quantity</td>

            <td>
              {invoiceDetails.items.reduce(
                (acc, item) => acc + item.quantity,
                0
              )}
            </td>
            <td className="label">Subtotal</td>
            <td>₹{subtotal.toFixed(2)}</td>
          </tr>
          <tr>
            <td className="label">Discount Rate (%):</td>
            <td>
              <input
                type="text"
                id="discount"
                value={invoiceDetails.discountRate}
                onChange={handleDiscountChange}
                min="0"
                max="100"
                step="0.1"
              />
            </td>

            <td className="label">GST Total</td>
            <td>₹{totalGst.toFixed(2)}</td>
          </tr>
          <tr>
            <td className="label">Discount ({invoiceDetails.discountRate}%)</td>
            <td>-₹{discountAmount.toFixed(2)}</td>
            <td className="label">IGST Total</td>
            <td>₹{totalIgst.toFixed(2)}</td>
          </tr>
          <tr>
            <td colSpan={2} className="total-label">
              <strong>Total Payable</strong>
            </td>
            <td colSpan={2} className="total-value">
              <strong>₹{totalPayable.toFixed(2)}</strong>
            </td>
          </tr>
        </tbody>
      </Table>

      <div className="bill-footer d-flex g-2 justify-content-evenly">
        <Button variant="danger">Cancel</Button>
        <Button variant="danger"> Hold Bill</Button>
        <Button variant="primary" onClick={handleOpenPaymentModal}>
          Make Payment
        </Button>
      </div>
      <p>Thank you for your business!</p>

      <Modal
        show={isPaymentModalOpen}
        onHide={() => setIsPaymentModalOpen(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Make Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="customerName">
            <Form.Label>Select Customer</Form.Label>
            <Form.Control
              as="select"
              value={selectedCustomer}
              onChange={(e) => setSelectedCustomer(e.target.value)}
            >
              <option value="">Select Customer</option>
              {customerList.map((customer) => (
                <option key={customer.id} value={customer.id}>
                  {customer.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="accountSelect">
            <Form.Label>Select Account</Form.Label>
            <Form.Control
              as="select"
              value={selectedAccount}
              onChange={(e) => setSelectedAccount(e.target.value)}
            >
              <option value="">Select account</option>
              {Array.isArray(settinglist) && settinglist.length > 0 ? (
                settinglist.map((account) => (
                  <option key={account.id} value={account.id}>
                    {account.name} {/* Ensure you show the account name */}
                  </option>
                ))
              ) : (
                <option disabled>No accounts available</option>
              )}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="paymentMethod">
            <Form.Label>Select Payment Method</Form.Label>
            <Form.Control
              as="select"
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(Number(e.target.value))} // Convert to number
            >
              <option value="">Select Payment Method</option>
              {paymentMethods.map((method) => (
                <option key={method.id} value={method.id}>
                  {method.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="paidAmount">
            <Form.Label>Paid Amount</Form.Label>
            <Form.Control
              type="number"
              value={paidAmount}
              onChange={(e) => setPaidAmount(Number(e.target.value))}
            />
          </Form.Group>

          <Form.Group controlId="returnChange">
            <Form.Label>Return Change</Form.Label>
            <Form.Control
              type="text"
              value={`₹${(paidAmount - totalPayable).toFixed(2)}`}
              readOnly
            />
          </Form.Group>

          <p>Total Payable Amount: ₹{totalPayable.toFixed(2)}</p>
          <p>Total Purchased Items: {invoiceDetails.items.length}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() =>
              handleMakePayment(
                selectedOutlet,
                selectedCustomer,
                paymentMethod,
                totalPayable,
                paidAmount,
                invoiceDetails,
                selectedAccount
              )
            }
          >
            Make Payment
          </Button>
          <Button
            variant="secondary"
            onClick={() => setIsPaymentModalOpen(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={isModalOpen} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Select Outlet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup>
            {outletList.map((outlet) => (
              <ListGroup.Item
                key={outlet.id}
                action
                onClick={() => handleSelectOutlet(outlet)}
                className="d-flex flex-column justify-content-center align-items-center"
              >
                <strong>{outlet.name}</strong>
                <div>{outlet.address}</div>
                <div>Mobile:{outlet.mobile}</div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <style jsx>{`
        .product-dropdown {
          list-style: none;
          padding: 0;
          margin: 0;
          border: 1px solid #ccc;
          max-height: 100px;
          overflow-y: auto;
          position: absolute;
          background-color: white;
          z-index: 10;
          width: calc(30% - 20px);
        }

        .dropdown-item {
          padding: 8px 12px;
          cursor: pointer;
        }

        .dropdown-item:hover {
          background-color: #f1f1f1;
        }

        .bill-table {
          margin-top: 20px;
        }
      `}</style>
    </div>
  );
};

export default Bill;
