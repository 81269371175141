import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";

const AddProduct = () => {
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("");
  const [productName, setProductName] = useState("");
  const [productCode, setProductCode] = useState("");
  const [productImage, setProductImage] = useState(null);
  const [purchasePrice, setPurchasePrice] = useState("");
  const [retailPrice, setRetailPrice] = useState("");
  const [gst, setGst] = useState("");
  const [igst, setIgst] = useState("");
  const [status, setStatus] = useState("active");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const token = localStorage.getItem("token");

        const response = await fetch(
          "https://luckytraders.bwsoft.in/api/categorylist/",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();

        if (Array.isArray(data)) {
          setCategories(data);
        } else {
          throw new Error("Categories data is not an array");
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
        setError("Failed to load categories.");
      }
    };

    fetchCategories();
  }, []);

  const handleProductImageChange = (e) => {
    setProductImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(false);

    // Ensure GST and IGST default to 0 if empty
    const gstValue = gst.trim() === "" ? 0 : gst;
    const igstValue = igst.trim() === "" ? 0 : igst;

    const formData = new FormData();
    formData.append("category", category);
    formData.append("name", productName);
    formData.append("code", productCode);
    if (productImage) {
      formData.append("image", productImage);
    }
    formData.append("purchase_price", purchasePrice);
    formData.append("retail_price", retailPrice);
    formData.append("gst", gstValue);
    formData.append("igst", igstValue);
    formData.append("status", status === "active");

    try {
      const token = localStorage.getItem("token");

      const response = await fetch(
        "https://luckytraders.bwsoft.in/api/product/create/",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to create product");
      }

      const data = await response.json();
      console.log("Product created:", data);
      setSuccess(true);

      // Clear input fields after success
      setCategory("");
      setProductName("");
      setProductCode("");
      setProductImage(null);
      setPurchasePrice("");
      setRetailPrice("");
      setGst("");
      setIgst("");
      setStatus("active");
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mt-4" id="tabelcontent">
      <h2>Create Product</h2>

      {error && <div className="alert alert-danger">{error}</div>}
      {success && (
        <div className="alert alert-success">Product created successfully!</div>
      )}

      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>Select Category *</Form.Label>
          <Form.Control
            as="select"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            required
          >
            <option value="">Select Category</option>
            {categories.length > 0 ? (
              categories.map((cat) => (
                <option key={cat.id} value={cat.id}>
                  {cat.name}
                </option>
              ))
            ) : (
              <option value="" disabled>
                Loading categories...
              </option>
            )}
          </Form.Control>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Product Name *</Form.Label>
          <Form.Control
            type="text"
            value={productName}
            onChange={(e) => setProductName(e.target.value)}
            placeholder="Enter product name"
            required
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Product Code *</Form.Label>
          <Form.Control
            type="text"
            value={productCode}
            onChange={(e) => setProductCode(e.target.value)}
            placeholder="Enter product code"
            required
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Product Image (optional)</Form.Label>
          <Form.Control type="file" onChange={handleProductImageChange} />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Purchase Price *</Form.Label>
          <Form.Control
            type="number"
            value={purchasePrice}
            onChange={(e) => setPurchasePrice(e.target.value)}
            placeholder="Enter purchase price"
            required
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Retail Price *</Form.Label>
          <Form.Control
            type="number"
            value={retailPrice}
            onChange={(e) => setRetailPrice(e.target.value)}
            placeholder="Enter retail price"
            required
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>GST *</Form.Label>
          <Form.Control
            type="number"
            value={gst}
            onChange={(e) => setGst(e.target.value)}
            placeholder="Enter GST"
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>IGST *</Form.Label>
          <Form.Control
            type="number"
            value={igst}
            onChange={(e) => setIgst(e.target.value)}
            placeholder="Enter IGST"
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Status *</Form.Label>
          <Form.Control
            as="select"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            required
          >
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </Form.Control>
        </Form.Group>

        <Button variant="primary" type="submit" disabled={loading}>
          {loading ? "Creating..." : "Create Product"}
        </Button>
      </Form>
    </div>
  );
};

export default AddProduct;
