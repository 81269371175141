import React, { useState, useEffect } from "react";
import Sidenav from "./Sidenav";
import { Link, useNavigate } from "react-router-dom";

const Header = ({ setIsAuthenticated }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const navigate = useNavigate();

  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleLogout = () => {
    // Remove the access token and other user-related data
    localStorage.removeItem("accessToken"); // If you're storing the token in local storage
    localStorage.removeItem("refreshToken"); // If applicable
    localStorage.removeItem("user"); // Remove any user data if stored

    // Optionally clear session storage
    sessionStorage.clear();

    // Optionally clear cookies (if using cookies)
    document.cookie =
      "accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    // Redirect to login page after logout
    window.location.href = "/auth"; // Adjust the path as needed
  };

  // Define items to search
  const sidenavItems = [
    { label: "Dashboard", path: "/Home" },
    { label: "Manage Category", path: "/ManageCategory" },
    { label: "Add Category", path: "/Addcategory" },
    { label: "Manage Products", path: "/Manageproduct" },
    { label: "Add Products", path: "/Addproduct" },
    { label: "All Products Label", path: "/Allproductlabel" },
    { label: "Manage Customers", path: "/ManageCustomer" },
    { label: "Add Customers", path: "/Addcustomer" },
    { label: "Manage Inventory", path: "/ManageInventory" },
    { label: "Add Inventory", path: "/AddInventory" },
    { label: "Billing", path: "/Bill" },
    { label: "Quotation", path: "/Quoatation" },
    { label: "Sales Reports", path: "/Salesreport" },
    { label: "Return Reports", path: "/Returnreport" },
    { label: "Outlets", path: "/Outlets" },
    { label: "Users", path: "/Users" },
    { label: "Suppliers", path: "/Suppliers" },
    { label: "System Settings", path: "/Systemsettings" },
    { label: "Payment Methods", path: "/PaymentMethods" },
    { label: "Create Bill", path: "/Createbill" },
    { label: "Charts", path: "/charts" },
    { label: "Tables", path: "/tables" },
  ];

  // Filter items based on search query
  useEffect(() => {
    if (searchQuery) {
      const matchedItems = sidenavItems.filter((item) =>
        item.label.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredItems(matchedItems);
    } else {
      setFilteredItems([]);
    }
  }, [searchQuery]);

  return (
    <div>
      <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
        {/* Search bar in the header */}
        <form
          className="navbar-search d-none d-md-block"
          style={{ position: "relative" }}
        >
          <div className="input-group" id="inputnavbarsearch">
            <input
              type="text"
              className="form-control bg-light border-0 small"
              placeholder="Search for..."
              aria-label="Search"
              aria-describedby="basic-addon2"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <div className="input-group-append">
              <button className="btn btn-primary" type="button">
                <i className="fas fa-search fa-sm"></i>
              </button>
            </div>
          </div>
          {/* Dropdown for search results */}
          {filteredItems.length > 0 && (
            <div
              className="dropdown-menu show"
              style={{
                position: "absolute",
                top: "100%",
                left: 0,
                width: "100%",
              }}
            >
              {filteredItems.map((item, index) => (
                <Link
                  key={index}
                  to={item.path}
                  className="dropdown-item"
                  onClick={() => setSearchQuery("")} // Clear search on click
                >
                  {item.label}
                </Link>
              ))}
            </div>
          )}
        </form>

        {/* Other Header Elements */}
        <ul className="navbar-nav ml-auto">
          <li className="nav-item dropdown no-arrow">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="userDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="mr-2 d-flex d-lg-inline text-gray-600 small">
                Lucky Traders
              </span>
              <img
                className="img-profile rounded-circle"
                src="assets/img/dummy.png"
                alt="Profile"
              />
            </a>
            <div
              className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
              aria-labelledby="userDropdown"
            >
              <a className="dropdown-item" href="/users">
                <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                Users
              </a>
              <div className="dropdown-divider"></div>
              <a className="dropdown-item" href="#" onClick={handleLogout}>
                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                Logout
              </a>
            </div>
          </li>
        </ul>

        <button
          id="sidebarToggleTop"
          className="btn btn-link d-md-none rounded-circle mr-3"
          onClick={handleSidebarToggle}
        >
          <i className="fa fa-bars"></i>
        </button>
      </nav>

      {/* Sidebar with toggle and search query */}
      <Sidenav isOpen={isSidebarOpen} searchQuery={searchQuery} />
    </div>
  );
};

export default Header;
