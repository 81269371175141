// import React, { useEffect, useState } from "react";
// import { useLocation } from "react-router-dom";
// import { Container, Row, Col, Table, Button } from "react-bootstrap";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import { GoX } from "react-icons/go";
// import { Modal, Typography, Box } from "@mui/material";
// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 400,
//   bgcolor: "background.paper",
//   borderRadius: "8px",
//   boxShadow: 24,
//   p: 4,
// };
// const Quotationbill = () => {
//   const location = useLocation();
//   const { quotationData } = location.state || {}; // Safe destructuring

//   const [selectedDate, setSelectedDate] = useState(new Date());
//   const [isCalendarOpen, setIsCalendarOpen] = useState(false);
//   const [settings, setSettings] = useState(null);
//   const [customer, setCustomer] = useState(null);
//   const [loading, setLoading] = useState(true);

//   // Simulate username and settings
//   const [username, setUsername] = useState("John Doe");

//   // Fetch settings based on account_id
//   useEffect(() => {
//     const fetchSettings = async () => {
//       try {
//         if (!quotationData || !quotationData.account_id) {
//           setLoading(false); // No account_id, stop loading
//           return;
//         }

//         const token = localStorage.getItem("token"); // Replace with your token logic
//         const response = await fetch(
//           `https://luckytraders.bwsoft.in/api/settinglist/${quotationData.account_id}/`,
//           {
//             method: "GET",
//             headers: {
//               Authorization: `Bearer ${token}`,
//               "Content-Type": "application/json",
//             },
//           }
//         );

//         if (response.ok) {
//           const data = await response.json();
//           setSettings(data);
//         } else {
//           console.error("Failed to fetch settings");
//         }
//       } catch (error) {
//         console.error("Error fetching settings:", error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchSettings();
//   }, [quotationData]);

//   useEffect(() => {
//     const fetchSettings = async () => {
//       try {
//         if (!quotationData || !quotationData.customer_id) {
//           setLoading(false); // No account_id, stop loading
//           return;
//         }

//         const token = localStorage.getItem("token"); // Replace with your token logic
//         const response = await fetch(
//           `https://luckytraders.bwsoft.in/api/getcustomer/${quotationData.customer_id}/`,
//           {
//             method: "GET",
//             headers: {
//               Authorization: `Bearer ${token}`,
//               "Content-Type": "application/json",
//             },
//           }
//         );

//         if (response.ok) {
//           const data = await response.json();
//           setCustomer(data);
//         } else {
//           console.error("Failed to fetch settings");
//         }
//       } catch (error) {
//         console.error("Error fetching settings:", error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchSettings();
//   }, [quotationData]);

//   const formatDate = (date) => {
//     return `${date.getDate().toString().padStart(2, "0")}/${(
//       date.getMonth() + 1
//     )
//       .toString()
//       .padStart(2, "0")}/${date.getFullYear()}`;
//   };

//   const formatCurrency = (amount) => {
//     const num = parseFloat(amount);
//     return isNaN(num)
//       ? "₹0.00"
//       : new Intl.NumberFormat("en-IN", {
//           style: "currency",
//           currency: "INR",
//         }).format(num);
//   };

//   if (loading) {
//     return <div>Loading...</div>; // Show loading indicator while fetching settings
//   }

//   if (!quotationData || !quotationData.items) {
//     return <div>No data available</div>;
//   }

//   const totalGst = quotationData.items.reduce(
//     (acc, item) => acc + ((item.gst * item.price * item.quantity) / 100 || 0),
//     0
//   );
//   const totalIgst = quotationData.items.reduce(
//     (acc, item) => acc + ((item.igst * item.price * item.quantity) / 100 || 0),
//     0
//   );

//   return (
//     <Container className="mt-4" id="tabelcontent">
//       <Row className="mb-3">
//         <Col className="d-flex justify-content-between align-items-center">
//           <h1>Quotation Bill</h1>
//           <Button variant="primary" onClick={() => window.print()}>
//             Print
//           </Button>
//         </Col>
//       </Row>

//       <div id="print-area">
//         {/* Header Section */}
//         <Row className="text-center mb-4">
//           <Col>
//             {settings?.logo ? (
//               <img
//                 src={settings.logo}
//                 alt="logo"
//                 className="quotationbillimg"
//                 style={{ maxWidth: "150px", marginBottom: "10px" }}
//               />
//             ) : (
//               <div style={{ height: "150px", marginBottom: "10px" }}>
//                 {/* Placeholder for logo */}
//                 <span>No Logo Available</span>
//               </div>
//             )}
//             <h2>{settings?.name || "Company Name"}</h2>
//             <h6>{settings?.address || "Company Address"}</h6>
//             {/* <p>Phone: {settings?.phone || "123456789"}</p> */}
//           </Col>
//         </Row>

//         <div className="d-flex justify-content-between align-items-center mb-3">
//           <h5>GSTIN: {settings?.gst_no || "N/A"}</h5>
//           <h5>Email: {settings?.email || "N/A"}</h5>
//         </div>

//         <hr />

//         <div className="d-flex justify-content-between align-items-start">
//           <div>
//             <p>Issued to</p>
//             <h5>{customer.name || "N/A"}</h5>
//             <p>{customer.address || "N/A"}</p>
//             <p>GST: {customer.customer_gst_no || "N/A"}</p>
//             <p>Mobile: {customer.mobile || "N/A"}</p>
//           </div>
//           <div>
//             <p>Shipping Address</p>
//             <h5>{customer.delivery_name || "N/A"}</h5>
//             <p>{customer.delivery_address || "N/A"}</p>
//             <p>GST: {customer.delivery_gst_no || "N/A"}</p>
//             <p>Email: {customer.email || "N/A"}</p>
//           </div>
//           <div className="create-bill">
//             <h5 className="mb-4">Quotation BILL</h5>
//             <div className="d-flex flex-column">
//               <div className="d-flex justify-content-start">
//                 <p className="mb-1 billcreate-left">Invoice No</p>
//                 <p className="billcreate-leftdot">:</p>
//                 <p className="mb-1 justify-content-start">
//                   <strong>{settings?.invoice_no || "N/A"}</strong>
//                 </p>
//               </div>
//               <div className="d-flex justify-content-start ">
//                 <p className="mb-1 justify-content-start billcreate-left">
//                   Date
//                 </p>
//                 <p className="billcreate-leftdot">:</p>
//                 <p className="mb-1 justify-content-start">
//                   <strong onClick={() => setIsCalendarOpen(true)}>
//                     {formatDate(selectedDate)}
//                   </strong>
//                 </p>

//                 <Modal
//                   open={isCalendarOpen}
//                   onClose={() => setIsCalendarOpen(false)}
//                   aria-labelledby="modal-title"
//                   aria-describedby="modal-description"
//                 >
//                   <Box sx={style}>
//                     <div className="d-flex justify-content-between">
//                       <Typography id="modal-title" variant="h6" component="h2">
//                         Select a Date
//                       </Typography>
//                       <Button
//                         variant="danger"
//                         onClick={() => setIsCalendarOpen(false)}
//                         sx={{ mt: 2 }}
//                       >
//                         <GoX />
//                       </Button>
//                     </div>
//                     <DatePicker
//                       selected={selectedDate}
//                       onChange={(date) => {
//                         setSelectedDate(date);
//                         setIsCalendarOpen(false); // Close the modal on date selection
//                       }}
//                       dateFormat="dd/MM/yyyy"
//                       todayButton="Today"
//                       inline
//                       style={{ width: "100%" }}
//                     />
//                   </Box>
//                 </Modal>
//               </div>
//               <div className="d-flex justify-content-start">
//                 <p className="mb-1 billcreate-left">Payment Mode</p>
//                 <p className="billcreate-leftdot">:</p>
//                 <p className="mb-1 justify-content-start">
//                   <strong>Cash</strong>
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div>
//         <hr />

//         {/* Items Table */}
//         <Table striped bordered hover>
//           <thead className="table-primary">
//             <tr>
//               <th>#</th>
//               <th>Product Name</th>
//               <th>HSN Code</th>
//               <th>Quantity</th>
//               <th>Price</th>
//               <th>GST (%)</th>
//               <th>IGST (%)</th>
//               <th>Total</th>
//             </tr>
//           </thead>
//           <tbody>
//             {quotationData.items.map((item, index) => (
//               <tr key={index}>
//                 <td>{index + 1}</td>
//                 <td>{item.product_name}</td>
//                 <td>{item.product_code}</td>
//                 <td>{item.quantity}</td>
//                 <td>{formatCurrency(item.price)}</td>
//                 <td>{item.gst}%</td>
//                 <td>{item.igst}%</td>
//                 <td>
//                   ₹
//                   {(
//                     item.price * item.quantity +
//                     (item.price * item.quantity * (item.gst || 0)) / 100 +
//                     (item.price * item.quantity * (item.igst || 0)) / 100
//                   ).toFixed(2)}
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </Table>

//         {/* Summary */}
//         <Table className="mt-4" striped bordered hover>
//           <tbody>
//             <tr>
//               <td>Subtotal</td>
//               <td className="text-end">
//                 {formatCurrency(quotationData.subtotal)}
//               </td>
//             </tr>
//             <tr>
//               <td>GST</td>
//               <td className="text-end">{totalGst.toFixed(2)}</td>
//             </tr>
//             <tr>
//               <td>IGST</td>
//               <td className="text-end">{totalIgst.toFixed(2)}</td>
//             </tr>
//             <tr>
//               <td>Total Amount</td>
//               <td className="text-end">
//                 {formatCurrency(quotationData.total_price)}
//               </td>
//             </tr>
//           </tbody>
//         </Table>
//       </div>

//       {/* Footer */}
// <Row className="mt-5">
//   <Col>
//     <div className="d-flex justify-content-between align-items-end">
//       <p>Customer Signature</p>
//       <p>Prepared By: {username}</p>
//       <p>Authorized Signature</p>
//     </div>
//   </Col>
// </Row>
//     </Container>
//   );
// };

// export default Quotationbill;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Modal, Typography, Box } from "@mui/material";
import { GoX } from "react-icons/go";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};

const Quotationbill = () => {
  const location = useLocation();
  const { quotationData, responseData } = location.state || {}; // Safe destructuring
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [settings, setSettings] = useState(null);
  const [customer, setCustomer] = useState(null);

  const { quotation_id } = responseData || {};
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        if (!quotationData || !quotationData.account_id) {
          setLoading(false); // No account_id, stop loading
          return;
        }

        const token = localStorage.getItem("token"); // Replace with your token logic
        const response = await fetch(
          `https://luckytraders.bwsoft.in/api/settinglist/${quotationData.account_id}/`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setSettings(data);
        } else {
          console.error("Failed to fetch settings");
        }
      } catch (error) {
        console.error("Error fetching settings:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSettings();
  }, [quotationData]);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        if (!quotationData || !quotationData.customer) {
          setLoading(false);
          return;
        }

        const token = localStorage.getItem("token");
        const response = await fetch(
          `https://luckytraders.bwsoft.in/api/getcustomer/${quotationData.customer}/`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const data = await response.json();

          setCustomer(data);
        } else {
          console.error("Failed to fetch settings");
        }
      } catch (error) {
        console.error("Error fetching settings:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSettings();
  }, [quotationData]);

  const formatDate = (date) => {
    return `${date.getDate().toString().padStart(2, "0")}/${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${date.getFullYear()}`;
  };

  const formatCurrency = (amount) => {
    const num = parseFloat(amount);
    return isNaN(num)
      ? "₹0.00"
      : new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(num);
  };

  if (
    !quotationData ||
    !quotationData.products ||
    quotationData.products.length === 0
  ) {
    return (
      <div className="text-center">No items available for this quotation</div>
    );
  }

  const totalGst = quotationData.products.reduce(
    (acc, item) =>
      acc +
      ((item.product.gst * item.product.retail_price * item.quantity) / 100 ||
        0),
    0
  );
  const totalIgst = quotationData.products.reduce(
    (acc, item) =>
      acc +
      ((item.product.igst * item.product.retail_price * item.quantity) / 100 ||
        0),
    0
  );
  const subtotal = quotationData.products.reduce(
    (acc, item) => acc + item.quantity * item.product.retail_price,
    0
  );
  const grandTotal = subtotal + totalGst + totalIgst;

  return (
    <Container className="mt-4" id="tabelcontent">
      <Row className="mb-3">
        <Col className="d-flex justify-content-between align-items-center">
          <h1>Quotation Bill</h1>
          <Button variant="primary" onClick={() => window.print()}>
            Print
          </Button>
        </Col>
      </Row>

      <div id="print-area">
        {/* Header Section */}

        <Row className="text-center mb-4">
          <Col>
            {settings?.logo ? (
              <img
                src={settings.logo}
                alt="logo"
                className="quotationbillimg"
                style={{ maxWidth: "150px", marginBottom: "10px" }}
              />
            ) : (
              <div style={{ height: "150px", marginBottom: "10px" }}>
                {/* Placeholder for logo */}
                <span>No Logo Available</span>
              </div>
            )}
            <h2>{settings?.name || "Company Name"}</h2>
            <h6>{settings?.address || "Company Address"}</h6>
          </Col>
        </Row>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h5>GSTIN: {settings?.gst_no || "N/A"}</h5>
          <h5>Email: {settings?.email || "N/A"}</h5>
        </div>
        <hr />
        <div className="d-flex justify-content-between align-items-start">
          <div>
            <p>Issued to:</p>
            <h5>{customer?.name || "N/A"}</h5>
            <p>{customer?.address || "N/A"}</p>
            <p>GST: {customer?.customer_gst_no || "N/A"}</p>
            <p>Email: {customer?.email || "N/A"}</p>
            <p>Mobile: {customer?.mobile || "N/A"}</p>
          </div>
          <div>
            <p>Shipping Address</p>
            <p>{customer?.delivery_name || "N/A"}</p>
            <p>{customer?.delivery_address || "N/A"}</p>
            <p>GST:{customer?.delivery_gst_no || "N/A"}</p>
          </div>

          <div>
            <div className="d-flex flex-column">
              <h5 className="mb-4">Quotation BILL</h5>
              <div className="d-flex justify-content-start">
                <p className="mb-1 billcreate-left">Quotation No</p>
                <p className="billcreate-leftdot">:</p>
                <p className="mb-1 justify-content-start">
                  <strong>{quotation_id || "N/A"}</strong>
                </p>
              </div>
              <div className="d-flex justify-content-start ">
                <p className="mb-1 justify-content-start billcreate-left">
                  Invoice Date
                </p>
                <p className="billcreate-leftdot">:</p>
                <p className="mb-1 justify-content-start">
                  <strong onClick={() => setIsCalendarOpen(true)}>
                    {formatDate(selectedDate)}
                  </strong>
                </p>

                <Modal
                  open={isCalendarOpen}
                  onClose={() => setIsCalendarOpen(false)}
                  aria-labelledby="modal-title"
                  aria-describedby="modal-description"
                >
                  <Box sx={style}>
                    <div className="d-flex justify-content-between">
                      <Typography id="modal-title" variant="h6" component="h2">
                        Select a Date
                      </Typography>
                      <Button
                        variant="danger"
                        onClick={() => setIsCalendarOpen(false)}
                        sx={{ mt: 2 }}
                      >
                        <GoX />
                      </Button>
                    </div>
                    <DatePicker
                      selected={selectedDate}
                      onChange={(date) => {
                        setSelectedDate(date);
                        setIsCalendarOpen(false); // Close the modal on date selection
                      }}
                      dateFormat="dd/MM/yyyy"
                      todayButton="Today"
                      inline
                      style={{ width: "100%" }}
                    />
                  </Box>
                </Modal>
              </div>
              <div className="d-flex justify-content-start">
                <p className="mb-1 billcreate-left">Payment Mode</p>
                <p className="billcreate-leftdot">:</p>
                <p className="mb-1 justify-content-start">
                  <strong>Cash</strong>
                </p>
              </div>
            </div>
          </div>
        </div>

        <hr />

        {/* Items Table */}
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Product Name</th>
              <th>HSN Code</th>
              <th>Quantity</th>
              <th>Price</th>
              <th>GST (%)</th>
              <th>IGST (%)</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {quotationData.products.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.product.name}</td>
                <td>{item.product.code}</td>
                <td>{item.quantity}</td>
                <td>{formatCurrency(item.product.retail_price)}</td>
                <td>{item.product.gst}%</td>
                <td>{item.product.igst}%</td>
                <td>
                  ₹
                  {(
                    item.quantity * item.product.retail_price +
                    (item.quantity *
                      item.product.retail_price *
                      (item.product.gst || 0)) /
                      100 +
                    (item.quantity *
                      item.product.retail_price *
                      (item.product.igst || 0)) /
                      100
                  ).toFixed(2)}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        {/* Summary */}
        <Table striped bordered hover>
          <tbody>
            <tr>
              <td>Total Purchase Quantity</td>

              <td className="text-end">
                {quotationData.products.reduce(
                  (acc, item) => acc + item.quantity,
                  0
                )}
              </td>
            </tr>
            <tr>
              <td>Subtotal</td>
              <td className="text-end">{formatCurrency(subtotal)}</td>
            </tr>
            <tr>
              <td>Total GST</td>
              <td className="text-end">{formatCurrency(totalGst)}</td>
            </tr>
            <tr>
              <td>Total IGST</td>
              <td className="text-end">{formatCurrency(totalIgst)}</td>
            </tr>
            <tr>
              <td>Grand Total</td>
              <td className="text-end">{formatCurrency(grandTotal)}</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <Row className="mt-5">
        <Col>
          <div className="d-flex justify-content-between align-items-end">
            <p>Customer Signature</p>
            <p>Prepared By: </p>
            <p>Authorized Signature</p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Quotationbill;
