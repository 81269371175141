import React, { useEffect, useState } from "react";
import { Table, Form, Button } from "react-bootstrap"; // Import necessary components
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import { Link } from "react-router-dom";
import Swal from "sweetalert2"; // For alert dialogs

const Users = () => {
  const [users, setUsers] = useState([]); // State for user data
  const [loading, setLoading] = useState(true); // Loading state
  const [searchTerm, setSearchTerm] = useState(""); // State for search input

  useEffect(() => {
    const fetchUsers = async () => {
      const token = localStorage.getItem("token"); // Retrieve access token

      try {
        const response = await fetch(
          "https://luckytraders.bwsoft.in/api/users/",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        if (Array.isArray(data)) {
          setUsers(data);
        } else {
          console.error("Expected an array but received:", data);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchUsers();
  }, []);

  // Function to handle user deletion
  const handleDelete = async (userId) => {
    const token = localStorage.getItem("token"); // Retrieve access token

    const confirmDelete = await Swal.fire({
      title: "Are you sure?",
      text: "This user will be permanently deleted!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    });

    if (confirmDelete.isConfirmed) {
      try {
        const response = await fetch(
          `https://luckytraders.bwsoft.in/api/user/delete/${userId}/`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          Swal.fire("Deleted!", "User has been deleted.", "success");
          setUsers(users.filter((user) => user.id !== userId)); // Update local state
        } else {
          throw new Error("Failed to delete user");
        }
      } catch (error) {
        console.error("Error deleting user:", error);
        Swal.fire("Error!", "Failed to delete the user.", "error");
      }
    }
  };

  if (loading) {
    return <div className="text-center">Loading...</div>; // Show loading message while fetching
  }

  // Handle search functionality
  const filteredUsers = users.filter(
    (user) =>
      user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.mobile.includes(searchTerm) ||
      user.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.last_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="container mt-4" id="tabelcontent">
      <div className="mb-3 d-flex flex-row flex-md-row gap-2 justify-content-between">
        <h1>Users</h1>
        <Link to="/CreateUsers">
          <Button variant="primary" className="mb-2 mb-md-0 me-md-2">
            Add User
          </Button>
        </Link>
      </div>
      <Form className="mb-3">
        <Form.Control
          type="text"
          placeholder="Search by email, username, or mobile..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Form>

      <Table striped bordered hover>
        <thead className="table-dark">
          <tr>
            <th>Email Address</th>
            <th>Username</th>
            <th>Mobile</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredUsers.length > 0 ? (
            filteredUsers.map((user) => (
              <tr key={user.id}>
                <td>{user.email}</td>
                <td>{user.username}</td>
                <td>{user.mobile}</td>
                <td>{user.first_name}</td>
                <td>{user.last_name}</td>
                <td>{user.is_active ? "Active" : "Inactive"}</td>
                <td>
                  <Link
                    to={`/Edituser/${user.id}`}
                    className="btn btn-warning btn-sm me-2"
                  >
                    Edit
                  </Link>
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => handleDelete(user.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7" className="text-center">
                No users found
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <div className="d-flex justify-content-between align-items-center">
        <div>
          Items per page:
          <Form.Select className="mx-2" defaultValue="10">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </Form.Select>
        </div>
        <div>
          1 – {filteredUsers.length} of {users.length}
        </div>
      </div>
    </div>
  );
};

export default Users;
