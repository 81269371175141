import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import Sidenav from "../mainpage/Sidenav";

const Home = () => {
  const areaChartRef = useRef(null);
  const barChartRef = useRef(null);
  const pieChartRef = useRef(null);

  useEffect(() => {
    // Area Chart
    const areaChart = new Chart(areaChartRef.current, {
      type: "line",
      data: {
        labels: ["January", "February", "March", "April", "May", "June"],
        datasets: [
          {
            label: "Sales (in rupee)",
            data: [1200, 1900, 3000, 5000, 2500, 7000],
            backgroundColor: "rgba(78, 115, 223, 0.1)",
            borderColor: "rgba(78, 115, 223, 1)",
            borderWidth: 2,
            fill: true,
          },
        ],
      },
    });

    // Bar Chart
    const barChart = new Chart(barChartRef.current, {
      type: "bar",
      data: {
        labels: ["Product A", "Product B", "Product C", "Product D"],
        datasets: [
          {
            label: "Units Sold",
            data: [50, 75, 150, 100],
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
            ],
            borderWidth: 1,
          },
        ],
      },
    });

    // Pie Chart
    const pieChart = new Chart(pieChartRef.current, {
      type: "doughnut",
      data: {
        labels: ["Online", "In-Store", "Wholesale"],
        datasets: [
          {
            data: [60, 30, 10],
            backgroundColor: [
              "rgba(78, 115, 223, 1)",
              "rgba(28, 200, 138, 1)",
              "rgba(54, 185, 204, 1)",
            ],
          },
        ],
      },
    });

    // Cleanup
    return () => {
      areaChart.destroy();
      barChart.destroy();
      pieChart.destroy();
    };
  }, []);
  return (
    <div className="home-main">
      <div className="container-fluid" id="content">
        {/* Page Heading */}
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
          <a
            href="#"
            className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
          >
            <i className="fas fa-download fa-sm text-white-50"></i> Generate
            Report
          </a>
        </div>

        {/* Content Row */}

        <div className="row">
          {/* Point of Sales Card */}
          <div className="col-xl-2 col-md-6  col-6 mb-4">
            <div className="card border-left-primary shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                      Point of Sales
                    </div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-shopping-cart fa-2x text-gray-300"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Sales Card */}
          <div className="col-xl-2 col-md-6 col-6 mb-4">
            <div className="card border-left-success shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                      Sales
                    </div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-money-bill-wave fa-2x text-gray-300"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Reports Card */}
          <div className="col-xl-2 col-md-6 col-6 mb-4">
            <div className="card border-left-info shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2 ">
                    <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                      Reports
                    </div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-chart-line fa-2x text-gray-300"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Outlets Card */}
          <div className="col-xl-2 col-md-6 col-6 mb-4">
            <div className="card border-left-warning shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                      Outlets
                    </div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-store fa-2x text-gray-300"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Users Card */}
          <div className="col-xl-2 col-md-6 col-6 mb-4">
            <div className="card border-left-primary shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                      Users
                    </div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-users fa-2x text-gray-300"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* System Settings Card */}
          <div className="col-xl-2 col-md-6 col-6 mb-4">
            <div className="card border-left-success shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                      System Settings
                    </div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-cogs fa-2x text-gray-300"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Content Row */}
        <div className="row">
          <div className="col-xl-8 col-lg-7">
            {/* Area Chart */}
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">
                  Sales Trend
                </h6>
              </div>
              <div className="card-body">
                <div className="chart-area">
                  <canvas ref={areaChartRef}></canvas>
                </div>
                <hr />
                Styling for the area chart can be found in the{" "}
                <code>/js/demo/chart-area-demo.js</code> file.
              </div>
            </div>

            {/* Bar Chart */}
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">
                  Top Products
                </h6>
              </div>
              <div className="card-body">
                <div className="chart-bar">
                  <canvas ref={barChartRef}></canvas>
                </div>
                <hr />
                Styling for the bar chart can be found in the{" "}
                <code>/js/demo/chart-bar-demo.js</code> file.
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-lg-5">
            {/* Pie Chart */}
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">
                  Sales Channels
                </h6>
              </div>
              <div className="card-body">
                <div className="chart-pie pt-4">
                  <canvas ref={pieChartRef}></canvas>
                </div>
                <hr />
                Styling for the donut chart can be found in the{" "}
                <code>/js/demo/chart-pie-demo.js</code> file.
              </div>
            </div>
          </div>
        </div>

        {/* Content Row */}
        <div className="row"></div>
      </div>
    </div>
  );
};

export default Home;
