import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { Form, Button } from "react-bootstrap";

const EditCategory = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [category, setCategory] = useState({ name: "", status: true });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const response = await axios.get(
          `https://luckytraders.bwsoft.in/api/getcategory/${id}/`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`, // Add token here
            },
          }
        );
        setCategory(response.data);
      } catch (err) {
        setError("Failed to fetch category");
        console.error(
          "Error fetching category:",
          err.response ? err.response.data : err.message
        );
      } finally {
        setLoading(false);
      }
    };

    fetchCategory();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCategory((prevCategory) => ({
      ...prevCategory,
      [name]: name === "status" ? e.target.checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(
        `https://luckytraders.bwsoft.in/api/category/update/${id}/`,
        category,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      alert("Category updated successfully");
      navigate("/manage-categories");
    } catch (err) {
      setError("Failed to update category");
      console.error(
        "Error updating category:",
        err.response ? err.response.data : err.message
      );
    }
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div className="container mt-4" id="tabelcontent">
      <h2>Edit Category</h2>
      {error && <div className="text-danger">{error}</div>}
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formCategoryName">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={category.name}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="formCategoryStatus">
          <Form.Label>Status</Form.Label>
          <Form.Check
            type="checkbox"
            label="Active"
            name="status"
            checked={category.status}
            onChange={handleChange}
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Update Category
        </Button>
      </Form>
    </div>
  );
};

export default EditCategory;
