import React, { useEffect, useState } from "react";
import { Table, Form, Button } from "react-bootstrap"; // Import necessary components
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import Swal from "sweetalert2"; // For alert dialogs
import { Link } from "react-router-dom";

const Suppliers = () => {
  const [suppliers, setSuppliers] = useState([]); // State for suppliers data
  const [loading, setLoading] = useState(true); // Loading state
  const [searchTerm, setSearchTerm] = useState(""); // State for search input

  useEffect(() => {
    const fetchSuppliers = async () => {
      const token = localStorage.getItem("token"); // Retrieve access token

      try {
        const response = await fetch(
          "https://luckytraders.bwsoft.in/api/supplierlist/",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        if (Array.isArray(data)) {
          setSuppliers(data);
        } else {
          console.error("Expected an array but received:", data);
        }
      } catch (error) {
        console.error("Error fetching suppliers:", error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchSuppliers(); // Call the function to fetch suppliers data
  }, []);

  const handleDelete = async (id) => {
    const token = localStorage.getItem("token"); // Retrieve access token

    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "This supplier will be permanently deleted!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        const response = await fetch(
          `https://luckytraders.bwsoft.in/api/supplier/delete/${id}/`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          setSuppliers((prev) => prev.filter((supplier) => supplier.id !== id));
          Swal.fire("Deleted!", "The supplier has been deleted.", "success");
        } else {
          throw new Error("Failed to delete supplier");
        }
      }
    } catch (error) {
      console.error("Error deleting supplier:", error);
      Swal.fire("Error!", "Failed to delete the supplier.", "error");
    }
  };

  if (loading) {
    return <div className="text-center">Loading suppliers...</div>; // Loading message
  }

  // Handle search functionality
  const filteredSuppliers = suppliers.filter((supplier) =>
    supplier.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="container mt-4" id="tabelcontent">
      <div className="mb-3 d-flex flex-row flex-md-row gap-2 justify-content-between">
        <h1>Suppliers</h1>
        <Link to="/Createoutlet">
          <Button variant="primary" className="mb-2 mb-md-0 me-md-2">
            Add Outlets
          </Button>
        </Link>
      </div>
      <Form className="mb-3">
        <Form.Control
          type="text"
          placeholder="Search by supplier name..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Form>

      <Table striped bordered hover>
        <thead className="table-dark">
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Mobile</th>
            <th>Address</th>
            <th>Tax (%)</th>
            <th>Created</th>
            <th>Updated</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredSuppliers.length > 0 ? (
            filteredSuppliers.map((supplier) => (
              <tr key={supplier.id}>
                <td>{supplier.name}</td>
                <td>{supplier.email}</td>
                <td>{supplier.mobile}</td>
                <td>{supplier.address}</td>
                <td>{supplier.tax}</td>
                <td>
                  {supplier.created_by.username} -{" "}
                  {new Date(supplier.created_at).toLocaleDateString()}
                </td>
                <td>
                  {supplier.updated_by ? (
                    <>
                      {supplier.updated_by.username} -{" "}
                      {new Date(supplier.updated_at).toLocaleDateString()}
                    </>
                  ) : (
                    "N/A"
                  )}
                </td>
                <td>
                  <Link to={`/Editsuppliers/${supplier.id}`}>
                    <Button variant="warning" size="sm" className="me-2">
                      Edit
                    </Button>
                  </Link>
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => handleDelete(supplier.id)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="8" className="text-center">
                No suppliers found
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <div className="d-flex justify-content-between align-items-center">
        <div>
          Items per page:
          <Form.Select className="mx-2" defaultValue="10">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </Form.Select>
        </div>
        <div>
          1 – {filteredSuppliers.length} of {suppliers.length}
        </div>
      </div>
    </div>
  );
};

export default Suppliers;
