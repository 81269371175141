// import React, { useState } from "react";
// import { Form, Button, Spinner } from "react-bootstrap"; // Import necessary components
// import Swal from "sweetalert2"; // For alert dialogs
// import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
// import { useNavigate } from "react-router-dom"; // For navigation

// const CreatePaymentMethod = () => {
//   const [paymentMethod, setPaymentMethod] = useState({
//     name: "",
//     status: "active", // Default to active
//   });
//   const [loading, setLoading] = useState(false); // Loading state
//   const navigate = useNavigate(); // For navigation

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setPaymentMethod({
//       ...paymentMethod,
//       [name]: value,
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true); // Set loading to true while submitting

//     const token = localStorage.getItem("token"); // Retrieve access token

//     try {
//       const response = await fetch("https://luckytraders.bwsoft.in/api/payment/create/", {
//         method: "POST",
//         headers: {
//           Authorization: `Bearer ${token}`,
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(paymentMethod), // Send payment method data
//       });

//       if (response.ok) {
//         Swal.fire(
//           "Success!",
//           "Payment method created successfully.",
//           "success"
//         );
//         navigate("/payment-methods"); // Redirect to the payment methods list
//       } else {
//         throw new Error("Failed to create payment method");
//       }
//     } catch (error) {
//       console.error("Error creating payment method:", error);
//       Swal.fire("Error!", "Failed to create payment method.", "error");
//     } finally {
//       setLoading(false); // Set loading to false after submitting
//     }
//   };

//   return (
//     <div className="container mt-4" id="tabelcontent">
//       <h1>Create Payment Method</h1>
//       <Form onSubmit={handleSubmit}>
//         <Form.Group controlId="formName">
//           <Form.Label>Name *</Form.Label>
//           <Form.Control
//             type="text"
//             name="name"
//             value={paymentMethod.name}
//             onChange={handleChange}
//             required
//           />
//         </Form.Group>

//         <Form.Group controlId="formStatus">
//           <Form.Label>Status *</Form.Label>
//           <Form.Select
//             name="status"
//             value={paymentMethod.status}
//             onChange={handleChange}
//             required
//           >
//             <option value="active">Active</option>
//             <option value="inactive">Inactive</option>
//           </Form.Select>
//         </Form.Group>

//         <Button
//           variant="primary"
//           type="submit"
//           className="mt-3"
//           disabled={loading}
//         >
//           {loading ? (
//             <Spinner animation="border" size="sm" />
//           ) : (
//             "Create Payment Method"
//           )}
//         </Button>
//       </Form>
//     </div>
//   );
// };

// export default CreatePaymentMethod;
import React, { useState } from "react";
import { Form, Button, Spinner } from "react-bootstrap"; // Import necessary components
import Swal from "sweetalert2"; // For alert dialogs
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import { useNavigate } from "react-router-dom"; // For navigation

const CreatePaymentMethod = () => {
  const [paymentMethod, setPaymentMethod] = useState({
    name: "",
    status: true, // Default to true (active)
  });
  const [loading, setLoading] = useState(false); // Loading state
  const navigate = useNavigate(); // For navigation

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPaymentMethod({
      ...paymentMethod,
      [name]: name === "status" ? value === "true" : value, // Convert string to boolean for status
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true while submitting

    const token = localStorage.getItem("token"); // Retrieve access token

    try {
      const response = await fetch(
        "https://luckytraders.bwsoft.in/api/payment/create/",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(paymentMethod), // Send payment method data
        }
      );

      if (response.ok) {
        Swal.fire(
          "Success!",
          "Payment method created successfully.",
          "success"
        );
        navigate("/payment-methods"); // Redirect to the payment methods list
      } else {
        throw new Error("Failed to create payment method");
      }
    } catch (error) {
      console.error("Error creating payment method:", error);
      Swal.fire("Error!", "Failed to create payment method.", "error");
    } finally {
      setLoading(false); // Set loading to false after submitting
    }
  };

  return (
    <div className="container mt-4" id="tabelcontent">
      <h1>Create Payment Method</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formName">
          <Form.Label>Name *</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={paymentMethod.name}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="formStatus">
          <Form.Label>Status *</Form.Label>
          <Form.Select
            name="status"
            value={paymentMethod.status.toString()} // Convert boolean to string for the select value
            onChange={handleChange}
            required
          >
            <option value={true}>Active</option>
            <option value={false}>Inactive</option>
          </Form.Select>
        </Form.Group>

        <Button
          variant="primary"
          type="submit"
          className="mt-3"
          disabled={loading}
        >
          {loading ? (
            <Spinner animation="border" size="sm" />
          ) : (
            "Create Payment Method"
          )}
        </Button>
      </Form>
    </div>
  );
};

export default CreatePaymentMethod;
