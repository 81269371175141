import React, { useEffect, useState } from "react";
import { Button, Table, Form, InputGroup } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2"; // Import SweetAlert2
import { Link, useNavigate } from "react-router-dom";

const ManageCustomer = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); // Use navigate hook for navigation

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleExportToExcel = () => {
    alert("Export to Excel functionality will go here");
  };

  useEffect(() => {
    const fetchCustomers = async () => {
      setLoading(true);
      const token = localStorage.getItem("token");

      try {
        const response = await axios.get(
          "https://luckytraders.bwsoft.in/api/customerlist/",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCustomers(response.data);
      } catch (error) {
        console.error("Error fetching customers:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCustomers();
  }, []);

  const handleDelete = async (customerId) => {
    const token = localStorage.getItem("token");

    const confirmDelete = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirmDelete.isConfirmed) {
      try {
        await axios.delete(
          `https://luckytraders.bwsoft.in/api/customer/delete/${customerId}/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Remove the deleted customer from the state
        setCustomers(
          customers.filter((customer) => customer.id !== customerId)
        );
        Swal.fire("Deleted!", "Customer has been deleted.", "success");
      } catch (error) {
        console.error("Error deleting customer:", error);
        Swal.fire("Error!", "Failed to delete customer.", "error");
      }
    }
  };

  return (
    <div className="container mt-4" id="tabelcontent">
      <div className="container d-flex flex-column flex-md-row align-items-center justify-content-between">
        <h2>Manage Customers</h2>

        <div className="mb-3 d-flex flex-row flex-md-row gap-2">
          <Link to="/Addcustomer">
            <Button
              variant="primary"
              className="mb-2 mb-md-0 me-md-2"
              onClick={() => navigate("/add-customer")}
            >
              Add Customer
            </Button>
          </Link>
          <Button
            variant="success"
            className="mb-2 mb-md-0 me-md-2"
            onClick={handleExportToExcel}
          >
            Export to Excel
          </Button>
        </div>
      </div>

      <InputGroup className="mb-3">
        <Form.Control
          type="text"
          placeholder="Search Customers..."
          value={searchTerm}
          onChange={handleSearch}
        />
      </InputGroup>

      {loading ? (
        <p>Loading...</p>
      ) : (
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Customer Name</th>
              <th>Address</th>
              <th>Email Address</th>
              <th>Mobile Number</th>
              <th>Created</th>
              <th>Updated</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {customers
              .filter((customer) =>
                customer.name.toLowerCase().includes(searchTerm.toLowerCase())
              )
              .map((customer) => (
                <tr key={customer.id}>
                  <td>{customer.name}</td>
                  <td>{customer.address}</td>
                  <td>{customer.email}</td>
                  <td>{customer.mobile}</td>
                  <td>{new Date(customer.created_at).toLocaleDateString()}</td>
                  <td>{new Date(customer.updated_at).toLocaleDateString()}</td>
                  <td>
                    <Button
                      variant="primary"
                      size="sm"
                      className="me-2"
                      onClick={() => navigate(`/UpdateCustomer/${customer.id}`)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => handleDelete(customer.id)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default ManageCustomer;
